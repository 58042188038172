/**   MAIN BANNER STYLES   **/
.main-banner {
  position: absolute;
  z-index: 0;
  width: 100%;
  overflow: hidden;
  transition-duration: 0.5s;
  .main-banner-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(10, 10, 10, 0.5);
  }
  .main-banner-img {
    width: 100%;
    height: auto;
    display: block;
  }
  @media(max-width: 768px) {
    .main-banner-img {
      width: 150%;
      margin-left: -48%;
    }
  }
}

/**   HEADER AD STYLES   **/
.header-apply {
  float: left;
  position: relative;
  display: inline-block;
  z-index: 2;
  padding: 0.5rem 0;
  padding-left: 2.5rem;
  .col.relative {
    min-height: 100%;
    position: absolute;
    .apply-img {
      width: auto;
      height: 130%;
      position: absolute;
      bottom: 0;
      left: 10%;
      margin-bottom: -0.5rem;
    }
  }
  p {
    margin: 0;
  }
  .apply-title {
    opacity: 0;
    padding-top: 0.5rem;
    &:first-of-type {
      margin-top: -0.5rem;
    }
    &.col-right {
      padding-top: 0;
      margin-top: -0.5rem;
    }
  }
  .ad-close {
    margin-left: 0.5rem;
  }
  a {
    margin-top: 0.6rem;
  }
  a.button {
    position: absolute;
    border: white solid 2px;
    text-transform: capitalize;
    font-family: $aktiv;
    font-size: 0.8rem;
    &:hover i.fa-angle-right {
      color: $orange;
    }
  }
  @media(max-width: 1024px) {
    h3{
      padding-top: 1rem;
      font-size: 2.8em;
    }
  }
  @media(max-width:768px){
    padding: 0.5rem 0 0.8rem 0;
    h3{
      padding-top: 0;
      margin-left: 0.5em;
      font-size: 2.8em;
    }
    a.button{
      padding: 0.8rem 2rem;
    }
    .apply-button{
      margin-top: -0.5rem;
    }
    .apply-image{
      display: none;
    }
  }
  @media(max-width: 500px){
    h3{
      font-size: 2.5em;
      padding-top: 0.25rem;
    }
  }
}

/**   HEADER SOCIAL BAR   **/
.header-bar {
  width: 100%;
  position: absolute;
  z-index: 99999;
  padding-top: 2rem;
  padding-bottom: 0.5rem;
  height: 6.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  background: linear-gradient($black 0%, transparent 90%);
  &.header-fixed{
    position:fixed;
    top: 0;
  }
  .header-logo {
    margin-top: -1rem;
  }
  .social-icon-block {
    margin-top: -0.5rem;
  }
  .header-right-side {
    margin-top: -1rem;
  }
  @media(max-width: 1024px) {
    padding-left: 3rem;
    padding-right: 3rem;
    .col-1\/3:nth-child(3) {
      white-space: nowrap;
    }
  }
  .header-find-near {
    .header-pin {
      height: 1.2rem;
      width: 0.8rem;
      display: inline;
      margin: 0 0.25rem;
    }
    p {
      display: inline;
      padding: 0;
      margin: 0;
    }
    .link-arrow {
      margin-left: 1rem;
    }
    &:hover {
      p {
        color: $orange;
      }
    }
  }
  .menu-icon {
    width: 2.3rem;
    height: auto;
    transition-duration: 0.5s;
    top: -1rem;
    position: absolute;
    cursor: pointer;
    * {
      transition-duration: 0.5s;
    }
    .menu-icon-circle {
      height: 1.2rem;
      width: 65%;
      margin: 0 auto;
      border-radius: 50%;
    }
    .menu-icon-top {
      height: 0.6rem;
      overflow-y: hidden;
      .menu-icon-circle {
        background: linear-gradient($yellow 0%, $orange 60%);
      }
    }
    .menu-icon-bottom {
      height: 0.6rem;
      overflow-y: hidden;
      .menu-icon-circle {
        margin-top: -30%;
        background: linear-gradient($yellow 50%, $orange 95%);
      }
    }
    .menu-icon-bar {
      height: 0.25rem;
      width: 100%;
      margin: 0.2rem 0;
      @media(max-width:400px){
        height: 2px;
      }
    }
    .menu-icon-bar.menu-icon-close-bottom, .menu-icon-bar.menu-icon-close-top {
      transition-duration: 0.5s;
    }
    .menu-icon-text {
      width: 100%;
      height: auto;
      padding: 0.2rem 0;
      text-align: center;
      letter-spacing: 2px;
    }
    &:hover, &.menu-icon-open {
      top: -1.3rem;
      .menu-icon-bar {
        margin: 0.3rem 0;
      }
      .menu-icon-bottom .menu-icon-circle {
        margin-top: -34%;
      }
      .menu-icon-text {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        span {
          font-size: 0.7rem;
          margin-left: -0.1rem;
        }
      }
    }
    &.menu-icon-open {
      .menu-icon-text {
        opacity: 0;
      }
      .menu-icon-close-top {
        transform: translateY(1.25rem) rotate(-405deg);
      }
      .menu-icon-close-bottom {
        transform: translateY(-1.25rem) rotate(405deg);
      }
    }
  }
  .social-icon-block {
    float: right;
    padding-top: 0.6rem;
    padding-right: 2rem;
  }
  a.button {
    padding: 0;
    float: right;
    i {
      font-size: 1rem;
      padding: 0 0.5rem;
      &:first-of-type {
        font-size: 1.5rem;
      }
    }
  }
  .header-left-side, .header-right-side {
    margin-top: 1rem;
  }
  @media(max-width: 768px) {
    img.header-logo {
      position: relative;
      height: 5rem;
    }
  }
}

/**   HEADER STYLE   **/
header {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9999;
  overflow-y: auto;
  overflow-x: hidden;
  transform: translate(-100%, 0);
  transition-duration: 0.5s;
  &.menu-open {
    transform: translate(0, 0);
    z-index: 10000;
  }
  .nav {
    background-color: $orange;
    background: linear-gradient($yellow 0%, $orange 50%);
    li {
      width: 30vw;
      position: relative;
      height: 8em;
      border-bottom: solid 1px rgba(50, 50, 50, 0.1);
      overflow: hidden;
      .menu-image-overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: $black;
          opacity: 0.4;
          z-index: 2;
        }
        .nav-background-image {
          position: absolute;
          width: 100%;
          height: auto;
          transition-duration: 0.5s;
          margin-top: -7%;
        }
      }
      a {
        position: absolute;
        height: 100%;
        width: 100%;
        text-align: center;
        z-index: 2;
        span {
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          right: 0;
          color: $white;
          font-family: $nimbus;
          font-weight: 900;
          text-transform: uppercase;
          font-size: 1.8rem;
        }
        .nav-icon-image {
          position: absolute;
          padding-right: 3rem;
          top: 50%;
          transform: translateY(-50%);
          left: 15%;
          height: auto;
          width: 4.5rem;
        }
      }
      &:hover .menu-image-overlay, &.nav-path-selected .menu-image-overlay {
        opacity: 1;
      }
    }
  }
  @media(max-width: 1024px) {
    .nav {
      li {
        width: 50vw;
      }
    }
  }
  @media(max-width: 768px) {
    .nav {
      li {
        width: 100vw;
      }
    }
  }
}

/**   SOCIAL ICON BLOCK   **/
.social-icon-block {
  a {
    display: inline-block;
    margin: 0.2rem 0.15rem;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
    position: relative;
    transition-duration: 0.5s;
    i {
      font-size: 0.8rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:hover {
      transform: scale(1.2);
    }
  }
}

/**   HERO BANNER BLOCK   **/
.hero-container {
  height: auto;
  background-size: cover;
  background-attachment: fixed;
  padding-top: 10rem;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.4) 0%, transparent 80%);
  .hero-upper-img-left, .hero-upper-img-right {
    position: absolute;
    right: 0%;
    transform: translate(100%, 0%);
    height: 56rem;
    width: auto;
    top: 10%;
    @media(max-width: 1024px) {
      height: 45rem;
      top: 13%;
    }
  }
  .row:first-of-type {
    @media(max-width: 1024px) {
      padding-bottom: 0;
    }
  }
  img.hero-upper-img-left {
    left: 0%;
    transform: translate(-100%, 0);
    top: 15%;
    z-index: 2;
    @media(max-width: 1024px) {
      left: -8%;
    }
    @media(max-width:768px){
      display: none;
    }
  }
  img.hero-upper-img-right {
    z-index: 0;
    @media(max-width: 1024px) {
      right: -5rem;
    }
  }
  .hero-content-container {
    width: 80%;
    margin: 0 auto;
    z-index: 2;
    position: relative;
    margin-top: 4rem;
    .hero-tagline-container {
      h1 {
        display: block;
        font-size: 8rem;
        img#image-marker:first-child {
          height: 6rem;
          max-height: 6rem;
          width: auto;
          max-width: 30rem;
          display: inline-block;
          vertical-align: middle;
          margin-top: -1rem;
          margin-right: 1rem;
          @media(max-width:2000px){
            max-height:7rem;
          }
        }
        #image-marker:last-of-type{
          max-height: 100%;
          height: 6rem;
        }
        @media(max-width:1024px){
          img#image-marker:first-of-type{
            height: 3.8rem;
          }
          #image-marker:last-of-type{
            height: 3.8rem;
          }
        }
      }
      h4 {
        width: 75%;
        letter-spacing: 4px;
      }
      @media(max-width: 1024px) {
        margin-top: 3rem;
        h1 {
          font-size: 4.7rem;
        }
        h4 {
          width: 85%;
        }
      }
      @media(max-width: 768px) {
        margin-top: 4rem;
        h1 {
          font-size: 5rem;
          img#image-marker:first-child {
            display: block;
            margin: 0 auto;
            height: 5rem;
            margin-bottom: 1rem;
            margin-top: -0.5rem;
          }
        }
        h4 {
          width: 100%;
          text-align: center!important;
          margin-bottom: 1rem;
        }
      }
    }
    .hero-description {
      width: 60%;
      margin: 0 auto;
      line-height: 1.5rem;
      text-shadow: 0 0 10px #000;
    }
    .hero-button {
      margin: 3rem 0;
    }
    @media(max-width: 1024px) {
      .hero-description {
        width: 80%;
      }
    }
  }
  .lower-hero-container {
    position: relative;
    display: inline-block;
    width: 100%;
    z-index: 3;
    padding-top: 4rem;
    .scissors-cut-right {
      position: absolute;
      right: -13.3rem;
      overflow: hidden;
      height: 100%;
      width: 130%;
      top: 4rem;
      //border: solid blue 1px;
      .scissors-wood-right {
        position: absolute;
        left: 0;
        height: auto;
        width: 100%;
        top: -50%;
      }
      &.scissors-cut-right-tablet {
        @media(max-width: 1024px) {
          position: absolute;
          right: -13.3rem;
          overflow: hidden;
          height: 100%;
          width: 130%;
          transform: rotate(-16deg);
          top: 12rem;
          .scissors-wood-right {
            transform: rotate(16deg);
            top: -17rem;
            left: -16rem;
            position: absolute;
            height: auto;
            width: 100%;
          }
        }
        @media(max-width: 768px) {
          display:none;
        }
      }
    }
    .scissors-cut-left {
      position: absolute;
      left: -15rem;
      overflow: hidden;
      height: 100%;
      width: 130%;
      top: 4rem;
      //border: solid red 1px;
      .scissors-wood-left {
        position: absolute;
        right: 0;
        height: auto;
        width: 100%;
        top: -50%;
      }
      &.scissors-cut-left-tablet {
        @media(max-width: 1024px) {
          transform: rotate(16deg);
          top: 12rem;
          left: -14.5rem;
          position: absolute;
          overflow: hidden;
          height: 100%;
          width: 130%;
          .scissors-wood-left {
            transform: rotate(-16deg);
            left: -16rem;
            top: -7.8rem;
            position: absolute;
            right: 0;
            height: auto;
            width: 100%;
          }
        }
        @media(max-width: 768px) {
          display:none;
        }
      }
    }
    .lower-hero-scissors {
      right: 0;
      left: -0.5rem;
      margin: auto;
      top: 1rem;
      position: absolute;
      width: 3.5rem;
      height: auto;
      @media(max-width: 1024px) {
        left: -1.25rem;
        bottom: 0;
        width: 2.5rem;
        top: -6rem;
      }
      @media(max-width: 768px) {
        top: -8rem;
      }
    }
    .lower-hero-foreground-img {
      position: absolute;
      margin-top: -18rem;
      height: 45rem;
      width: auto;
      transform: translateX(-100%);
    }
    .lower-hero-title-container {
      width: 100%;
      padding-top: 15rem;
      padding-bottom: 3rem;
      position: relative;
      display: inline-block;
      h3 {
        letter-spacing: 0.2rem;
        font-weight: 900;
      }

      @media(max-width:1024px) {
        padding-top: 3rem;
      }
    }
    @media(max-width: 1024px) {
      margin-top: -4rem;
    }
  }
  @media(max-width: 768px) {
    padding-top: 1rem;
    .hero-content-container {
      width: 100%;
      .hero-tagline-img {
        margin-top: 10rem;
      }
    }
    .lower-hero-container {
      .lower-hero-scissors {
        bottom: -2rem;
        left: -0.5rem;
      }
      .lower-hero-title-container {
        padding-bottom: 1rem;
        h3 {
          font-size: 2rem;
          padding-top: 1rem
        }
        h5 {
          font-size: 1.5rem;
        }
      }
    }
  }
  @media(max-width: 500px) {
    .lower-hero-container {
      .lower-hero-scissors {
        bottom: -2rem;
        left: -1.5rem;
      }
    }
  }
}

/**   CUSTOM MAP HOME   **/
.custom-map-block:not(.full-page-map), .custom-map-block.home-page-map {
  position: relative;
  z-index: 4;
  .google-map-brush {
    position: absolute;
    right: -5rem;
    top: -8rem;
    width: 15rem;
    height: auto;
    z-index: 10;
    animation: linear floating 10s infinite;
  }
  .google-map-container {
    background-image: $woodBackground;
    min-height: 45rem;
    margin-bottom: -20rem;
    @media(max-width: 500px) {
      height: 55rem;
    }
  }
  .map-search-box {
    position: absolute;
    background-color: $orange;
    background: linear-gradient($yellow 0%, $orange 60%);
    display: block;
    z-index: 10;
    width: 45%;
    margin: auto;
    left: 0;
    right: 0;
    padding: 1.5rem;
    text-align: center;
    input {
      height: 3rem;
      width: 85%;
      background-image: url(../img/map_pin_grey.png);
      background-position: 4% 30%;
      background-repeat: no-repeat;
      margin-right: 0.5rem;
      padding-left: 4rem;
      &::-webkit-input-placeholder {
        font-family: $aktiv;
        font-weight: 900;
        font-size: 0.8rem;
        color: #767676;
        letter-spacing: 1px;
        line-height: 0.8rem;
      }
      &:focus {
        &::-webkit-input-placeholder {
          color: transparent;
        }
      }
    }
    .button {
      height: 3rem;
      margin-top: 0.1rem;
      display: inline-block;
      padding: 0.68rem 1rem;
      i {
        padding-right: 0.2rem;
      }
      &:after {
        background-position: center;
        position: static;
      }
    }
    @media(max-width: 1024px) {
      width: 65%;
      input {
        width: 85%;
      }
    }
  }
  &.home-page-map .full-page-map-container {
    position: relative;
    .no-results-found {
      height: 150%;
    }
  }
  @media(max-width: 768px) {
    .map-search-box {
      width: 100%;
      input {
        width: 86%;
        text-indent: 4rem;
        background-size: 1.5rem;
        padding-left: 0;
        &::-webkit-input-placeholder {
          font-size: 0.7rem;
        }
      }
    }
  }
}

/**   IT'S A GUY THING BLOCK   **/
.guy-thing-block {
  position: relative;
  padding: 23rem 0;
  margin-bottom: -20rem;
  .guy-thing-shaver {
    position: absolute;
    left: -4rem;
    top: 0;
    animation: linear floating 10s infinite;
    width: 45%;
    height: auto;
    z-index: 3;
  }
  .guy-thing-contents {
    text-shadow: 0 0 1rem rgba(100, 100, 100, 0.5);
    float: left;
    z-index: 2;
    padding: 0 2rem;
    position: relative;
    img, .video-container {
      width: 95%;
      height: auto;
      box-shadow: -0.5rem 0.5rem 0.8rem rgba(0, 0, 0, 0.5);
      overflow: hidden;
      @media screen and (min-width: 769px) and (max-width: 1024px) {
        height: 20vw;
      }
    }
    .guy-thing-container {
      margin-top: 1.5rem;
      h2 {
        height: 6rem;
        line-height: 4.5rem;
        margin-bottom: 1.5rem;
        width: auto;
        display: inline-block;
        text-align: center;
        span {
          //height: 90%;
          //display: inline-block;
          //color: #fbc61e;
          //position: relative;
          //text-shadow: none;
          //padding: 0.5rem;
          display: block;
          width: 100%;
          text-align: center;
          .guy-thing-mustache {
            height: 1rem;
            width: auto;
            box-shadow: none;
            display: block;
            margin: 0 auto;
          }
        }
      }
      .guy-thing-description {
        h5 {
          letter-spacing: 1.2px;
        }
        p {
          width: 80%;
          font-size: 1.1rem;
          line-height: 1.8rem;
        }
      }
      .guy-thing-button {
        a.button {
          text-transform: capitalize;
          font-family: $aktiv;
          font-size: 0.8rem;
          padding: 1rem 1.8rem;
          &:hover {
            text-shadow: none;
          }
        }
      }
    }
  }
  .guy-thing-scissors {
    position: absolute;
    z-index: 0;
    margin-top: -5rem;
    right: -5%;
    width: 40%;
    bottom: 0;
    animation: linear floating 10s infinite reverse;
  }
  @media(max-width: 1024px) {
    padding: 13rem 0 11rem 0;
    margin-bottom: -10rem;
    .guy-thing-contents {
      .guy-thing-container {
        .guy-thing-description {
          p {
            width: 95%;
            font-size: 1rem;
            line-height: 1.4rem;
          }
        }
      }
    }
  }
  @media(max-width: 768px) {
    padding: 7rem 0 13rem 0;
    margin-bottom: -8rem;
    .guy-thing-contents {
      padding: 0;
    }
    .guy-thing-container {
      padding-left: 5%;
      padding-top: 2rem;
      padding-bottom: 0;
      h2 {
        font-size: 3.5rem;
      }
    }
  }
}

/**   SERVICES SLIDER BLOCK   **/
.services-slider-block {
  margin-top: 7rem;
  .services-slider-title {
    line-height: 4.5rem;
  }
  .service-slider-container {
    position: relative;
    z-index: 2;
    margin-top: 4rem;
    .service-slider {
      width: 95%;
      height: auto;
      margin: auto;
      cursor: pointer;
      .service-slide {
        padding: 0 1.5rem;
        position: relative;
        .service-slide-background {
          position: relative;
          float: left;
          border-bottom: solid 0.4rem transparent;
          .services-slide-background-img {
            width: 100%;
            height: 100%;
          }
        }
        .service-slide-content {
          position: absolute;
          z-index: 3;
          bottom: 2rem;
          text-align: center;
          margin: auto;
          left: 0;
          right: 0;
          h3 {
          }
          .services-hover {
            display: none;
            p {
              //display: none;
              line-height: 1.4rem;
              width: 80%;
              margin: 0 auto;
              padding: 1rem 0;
            }
            .services-arrow {
              margin: 0.5rem 0;
              img {
                margin: 0 auto;
                height: 1rem;
                width: auto;
              }
            }
          }
        }
        &:hover {
          .service-slide-background {
            border-bottom: solid 0.4rem $yellow;
          }
        }
      }
    }
  }
  @media(max-width: 1024px) {
    margin-top: 3rem;
  }
}
.force-visible {
  opacity: 1 !important;
  visibility: visible !important;
  display: block !important;
}

/**   AWESOME HAPPENINGS BLOCK   **/
.awesome-happenings-full-list, .social-block-list {
  padding: 2rem 0;
  .happenings-header {
    padding-bottom: 2rem;
    h1 {
      letter-spacing: 1px;
      line-height: 4.5rem;
      font-size: 6.5rem;
      .happenings-beard-guy {
        margin: 0 -1rem;
        height: 4rem;
        width: auto;
      }
    }
    p {
      font-size: 1.5rem;
      letter-spacing: 1px;
    }
  }
  &.awesome-happenings-home {
    margin-top: -5rem;
    margin-bottom: -6rem;
    .happenings-header {
      padding-left: 11.5%;
      img {
        height: 5rem;
      }
    }
  }
  .happenings {
    padding: 0 1rem;
    .clear-left {
      clear: left;
    }
    .happenings-background {
      height: 15rem;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: $black;
      background-position: top center;
      &.tweet-image{
        background-size: contain;
      }
    }
    .happenings-content {
      padding: 2rem 1rem;
      height: 20rem;
      position: relative;
      .happenings-title {
        letter-spacing: 1px;
      }
      .happenings-description {
        padding: 1rem 0;
        line-height: 1.4rem;
        overflow-y: auto;
        height: 14rem;
        @media(max-width: 1024px) {
          height: 7rem;
          overflow: auto;
        }
        @media(max-width: 768px) {
          height: 11rem;
        }
      }
      a {
        color: $orange;
        font-family: $aktiv;
        position: absolute;
        bottom: 1rem;
        left: 2rem;
        @media(max-width: 768px) {
          bottom: 2rem;
          left: 3rem;
        }
      }
    }
  }
  a {
    transition-duration: 0.5s;
    i {
      margin-left: 0.5rem;
    }
  }
  a:hover {
    i {
      animation: pulse 2s infinite;
    }
  }
  .happenings-button-container {
    text-align: center;
    padding-top: 2rem;
    .uil-ripple-css {
      margin: 0 auto;
      display: none;
    }
  }
  @media(max-width: 1024px) {
    &.awesome-happenings-home {
      .happenings-header h1 {
        font-size: 4.5rem;
        img {
          height: 3.5rem;
        }
      }
    }
    .happenings {
      .happenings-background {
        height: 9rem;
      }
      .happenings-content {
        padding: 1rem;
        height: 17rem;
        .happenings-description {
          line-height: 1.1rem;
          padding: 0.5rem 0;
        }
      }
    }
  }
  @media(max-width: 768px) {
    &.awesome-happenings-home {
      margin-top: -9rem;
      .happenings-header {
        padding-left: 1%;
        h1 {
          font-size: 3rem;
          img {
            height: 2.5rem;
            padding: 0 0.5rem;
          }
        }
      }
    }
    .happenings-header {
      padding-left: 0;
      h1 {
        font-size: 2.7rem;
        letter-spacing: 1px;
        .happenings-beard-guy {
          height: 2.5rem;
          padding: 0 0.3rem;
        }
      }
      h5 {
        font-size: 1.1rem;
      }
    }
    .happenings .happenings-background {
      height: 13rem;
    }
    .happenings.happenings-list-container{
      .happenings-description {
        font-size: 1.2rem;
        line-height: 1.4rem;
      }
    }
  }
}
@media(max-width:500px) {
  .social-block-list .happenings.happenings-list-container{
    .happenings-background {
      height: 15rem;
      background-position: center top;
    }
  }

  .happenings-description {
    font-size: 1.4rem!important;
    line-height: 1.7rem;
  }
}


.social-block-list{
  .happenings{
    .happenings-content{
      .happenings-description{
        a.social-link{
          position: relative;
          bottom: 0;
          left: 0;
        }
      }
    }
  }
}

.happenings-more-container {
  display: none;
}

.happenings-slider-pagination {
  .button {
    padding: 1rem;
    height: 3rem;
    width: 3rem;
    margin: 0 .5rem;
    &:hover i {
      animation: pulse 2s infinite;
    }
  }
}

/**   GIVING BACK BLOCK   **/
.giving-back-block {
  display: inline-block;
  position: relative;
  padding: 25rem 0 20rem 0;
  .giving-back-razer-img {
    position: absolute;
    height: auto;
    width: 25%;
    left: -10%;
    float: left;
    top: 0;
    z-index: 10;
  }
  .giving-back-comb-img {
    position: absolute;
    float: right;
    height: auto;
    width: 40%;
    right: -2%;
    top: 0;
    z-index: 10;
  }
  .giving-back-content {
    float: left;
    position: relative;
    z-index: 1;
    .giving-back-content-container {
      padding-left: 2rem;
      *:not(.background-white) {
        text-shadow: 0 0 1rem rgba(50, 50, 50, 0.6);
        @media(max-width: 500px) {
          .giving-back-subtitle{
            font-size: 1.6rem;
          }
          .giving-back-quote p{
            text-shadow: 0 0 1rem $black
          }
        }
      }
      .giving-back-header {
        line-height: 4.5rem;
        span {
          color: #eb5d1b;
          padding: 0.5rem;
          display: inline-block;
          text-shadow: none;
        }
      }
      .giving-back-subtitle {
        font-weight: 900;
      }
      .giving-back-quote {
        width: 75%;
        p {
          line-height: 1.6rem;
          margin: 0;
        }
      }
      .giving-back-award-container {
        margin-top: 1.5rem;
        img {
          display: inline-block;
          width: 80%;
          height: auto;
        }
        h4 {
          margin-top: 0.6rem;
          display: inline-block;
          font-size: 2.3rem;
        }
        h5 {
          display: inline-block;
        }
      }
      @media(max-width: 1024px) {
        margin-top: 6rem;
        .giving-back-quote {
          width: 90%;
          p {
            line-height: 1.4rem;
          }
        }
        .giving-back-award-container {
          img {
            width: 70%;
          }
          h4 {
            font-size: 2rem;
            margin-top: 0;
          }
        }
      }
      @media(max-width: 768px) {
        margin-top: 0;
        padding-bottom: 6rem;
      }
      .giving-back-subtitle {
        font-size: 1.1rem;
      }
    }
  }
  @media(max-width: 1024px) {
    padding: 5rem 0 5rem 0;
    .giving-back-content {
      .giving-back-icon-container {
        top: -20%;
      }
      .giving-back-header {
        h1 {
          font-size: 4rem;
          line-height: 3.5rem;
        }
      }
      .giving-back-content-container {
        margin-bottom: 4rem;
        .giving-back-award-container {
          margin-top: 0.5rem;
        }
      }
      p {
        margin: 0.5rem 0;
      }
    }
  }
  @media(max-width: 768px) {
    .giving-back-content .giving-back-content-container {
      margin-top: 6rem;
      margin-bottom: 2rem;
      .giving-back-quote {
        width: 95%;
      }
    }
  }
  @media(max-width: 500px) {
    .giving-back-content .giving-back-content-container {
      margin-top: 3rem;
      margin-bottom: 1rem;
    }
  }
}

.giving-back-scissors-img {
  position: absolute;
  bottom: 13.5%;
  left: 0;
  right: 0;
  margin: auto;
  width: 3rem;
  height: auto;
  @media(max-width: 1024px) {
    bottom: 11%;
  }
}

.giving-back-gallery-container {
  position: absolute;
  top: 16.75%;
  left: -10%;
  height: 66.5%;
  width: 120%;
  transform: rotate(13.8deg);
  overflow: hidden;
  .give-back-gallery {
    width: 50vw;
    position: absolute;
    z-index: 5;
    top: 0;
    left: 5%;
    transform: rotate(-13.8deg);
    .gallery-animated-container {
      height: 12rem;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: auto;
        min-height: 100%;
      }
    }
  }
  @media(max-width: 1024px) {
    transform: rotate(16.4deg);
    top: 17%;
    height: 66%;
    .give-back-gallery {
      transform: rotate(-16.4deg);
      .gallery-animated-container {
        height: 9rem;
      }
    }
  }
}

/**   CONCTACT FORM HOME   **/
.home-contact-form, .contact-form {
  width: 80%;
  margin: 0 10%;
  padding: 3rem 0 1rem;
  .home-contact-header, .contact-header {
    padding: 2rem 0;
    h1 {
      line-height: 4rem;
    }
    .home-contact-title {
      .home-contact-beard-guy {
        padding: 0;
        height: 100%;
        width: auto;
        margin: 0 -1rem;
      }
    }
  }
  form {
    .fields {
      margin: 0 -1rem;
      .field {
        padding: 0 1rem;
        position: relative;
        label {
          color: $dark-grey;
          font-family: $aktiv;
          font-size: 1rem;
          position: absolute;
          transition-duration: 0.5s;
          top: -1rem;
          left: 1rem;
          transform: translateY(2rem);
        }
        input[value], textarea, .select2 {
          width: 100%;
          background-color: transparent;
          border: none;
          color: $dark-grey;
          padding: 1.5rem 0 0.2rem 0;
          border-bottom: solid 2px $dark-grey;
          font-size: 1rem;
          &::placeholder {
            color: transparent;
          }
          &:focus, &:not(:placeholder-shown) {
            box-shadow: none;
            border-bottom-color: $orange;
            + label {
              transform: translateY(0.75rem);
              font-size: 0.8rem;
              color: $orange;
            }
          }
        }
      }
      .select2 {
        width: 50%;
        float: left;
        .select2-container {
          outline: none;
          &:focus {
            outline: none;
          }
          a.select2-choice {
            background-color: transparent;
            border-bottom: solid 2px $dark-grey;
            border-radius: 0;
            padding: 1.55rem 0 0.2rem 0;
            margin-top: 1.2rem;
            &.select2-default .select2-chosen {
              display: none;
            }
            &:not(.select2-default) + label {
              color: red;
            }
            .select2-chosen {
              font-family: $aktiv;
              color: $dark-grey;
              font-size: 1.2rem;
              margin-top: -1.8rem;
            }
            &:focus, &:hover {
              outline: none;
              text-decoration: none;
            }
            .select2-arrow {
              border: none;
              width: 4%;
              right: 0.5rem;
              b {
                display: none;
              }
              &::before {
                content: '';
                border-left: 0.5rem solid transparent;
                border-right: 0.5rem solid transparent;
                border-bottom: 0.5rem solid $dark-grey;
                position: absolute;
                top: 0;
              }
              &::after {
                content: '';
                border-left: 0.5rem solid transparent;
                border-right: 0.5rem solid transparent;
                border-top: 0.5rem solid $dark-grey;
                position: absolute;
                bottom: 0.4rem;
              }
            }
          }
          &.select2-dropdown-open {
            background-color: $black;
            border: none;
            .select2-chosen {
              background-color: $black;
            }
            .select2-arrow {
              background-color: $black;
              width: 4%;
            }
            .select2-choice {
              background-color: $black;
            }
            * {
              border: none;
            }
          }
        }
        &.input-has-content {
          label {
            transform: translateY(1rem);
            font-size: 0.8rem !important;
          }
        }
      }
      field-date.input-has-content {
        label {
          transform: translateY(1rem);
          font-size: 0.8rem !important;
        }
      }
    }
    .form-actions {
      text-align: center;
      .button {
        padding: 1rem 5rem;
      }
    }
  }
  &:not(.contact-form) {
    width: 72%;
    margin: 0 14%;
    form .fields .field label {
      color: $dark-grey;
      font-weight: 900;
      font-size: 0.9rem;
      span {
        color: $orange;
      }
    }
    .input-has-content {
      .select2-container .select2-choice {
        border-bottom-color: $orange;
      }
      label {
        font-size: 1rem !important;
        color: $orange !important;
      }
    }
  }
  @media(max-width: 1024px) {
    &:not(.home-contact-form) {
      width: 110%;
      padding: 8rem 0 16rem 0;
      .contact-header {
        h1 {
          font-size: 3rem;
        }
      }
      form .fields {
        .form-group {
          padding: 0 1rem;
        }
        label {
          transform: translateY(1rem);
        }
      }
    }
    &:not(.contact-form) {
      .home-contact-header .home-contact-title {
        img.home-contact-beard-guy {
          height: 3rem;
          padding: 0 0.5rem;
        }
      }
      .select2 .select2-container {
        a.select2-choice {
          margin-top: 1.1rem !important;
          .select2-arrow {
            &::before {
              top: 0;
            }
            &::after {
              bottom: 0;
            }
          }
        }
      }
    }
  }
  @media(max-width: 768px) {
    padding-bottom: 6rem;
    .home-contact-header {
      .home-contact-title {
        font-size: 3.5rem;
        .home-contact-beard-guy {
          height: 3rem;
          padding: 0 0.5rem;
        }
      }
      h5 {
        font-size: 1rem;
      }
    }
    form .fields .select2 {
      width: 100%;
      .select2-arrow {
        &::before {
          top: 0.2rem;
        }
        &::after {
          bottom: 0.2rem;
        }
      }
    }
    form .fields .field {
      padding: 0.5rem 1rem;
    }
    form .form-actions {
      text-align: center;
    }
  }
  @media(max-width: 500px) {
  }
  form .fields .select2 .select2-arrow {
    &::before {
      top: 0.3rem;
    }
    &::after {
      bottom: 0.3rem;
    }
  }
}

.contact-form {
  padding: 24rem 0 29rem 0;
  width: 100%;
  .contact-header {
    h1 {
      width: 100%;
      margin-left: 8.5%;
      font-size: 5.5rem;
      text-align: left;
      span {
        color: $orange;
      }
    }
  }
  form {
    width: 83%;
    margin: 0 auto;
    .fields .field {
      input[value], textarea, .select2 {
        &:focus, &:not(:placeholder-shown) {
          border-bottom-color: $black;
          + label {
            color: $black;
          }
          color: $black;
        }
        border-bottom-color: $white;
        + label {
          color: $white;
        }
      }
      label {
        color: $white;
      }
    }
  }
  form .fields .select2 .select2-container {
    a.select2-choice {
      border-bottom-color: $white;
      .select2-arrow::before {
        border-bottom-color: $white;
      }
      .select2-arrow::after {
        border-top-color: $white;
      }
    }
  }
  .input-has-content {
    .select2-container .select2-choice {
      border-bottom-color: $black !important;
      .select2-chosen {
        color: $black !important;
        margin-top: 0!important;
      }
    }
    label {
      color: $black !important;
      transform: translateY(0.75rem) !important;
      font-size: 1rem !important;
      span {
        color: $black;
      }
    }
  }
  .form-error {
    color: $black;
  }
  @media(max-width: 1024px) {
    margin-left: 0;
    .contact-header {
      text-align: left;
      h1 {
        margin-left: 0;
      }
    }
    form {
      width: 100%;
    }
  }
  @media(max-width: 768px) {
    width: 100% !important;
    margin: 0;
    padding: 16rem 0;
    form {
      width: 90%;
      margin: 0 auto;
      margin-top: 2rem;
      .form-group.field {
        padding: 0.5rem 1rem !important;
      }
    }
    .contact-header {
      padding-bottom: 0;
    }
    &:not(.home-contact-form) {
      padding: 6rem 0 10rem 0;
      form {
        width: 100%;
        .fields {
          .field {
            input, textarea {
              padding: 0.5rem 0 0.1rem 0;
            }
            &.select2 {
              a.select2-choice {
                padding: 0;
                .select2-chosen {

                }
              }
            }
          }
        }
      }
    }
  }
}

/**   FOOTER BANNER STYLES   **/
.footer-banner {
  .col-1\/2 {
    overflow: hidden;
  }
  .footer-banner-container {
    width: 85%;
    margin: 0 7.5%;
    .footer-banner-title h1 {
      line-height: 4.5rem;
    }
    .footer-banner-subtitle {
      line-height: 1.5rem;
    }
    a.button {
      font-family: $aktiv;
      text-transform: capitalize;
      font-size: 0.8rem;
    }
  }
  .footer-banner-img {
    background-size: cover;
    background-position: top right;
  }
  @media(max-width: 1024px) {
    .footer-banner-container {
      z-index: 2;
      .footer-banner-title h1 {
        font-size: 3rem;
        line-height: 2.5rem;
      }
      .footer-banner-subtitle {
        line-height: 1.3rem;
        font-size: 0.7rem;
      }
    }
    .col-1\/2:nth-child(2) {
      overflow: hidden;
      .footer-banner-img {
        height: 100%;
        width: auto;
        margin-left: -50%;
      }
    }
  }
}

/**   FOOTER STYLES   **/
footer {
  .scroll-to-top {
    background-color: $yellow;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.6rem 0.8rem;
    cursor: pointer;
    z-index: 10;
    &:hover img {
      animation: pulse infinite 2s;
    }
    img {
      height: 0.8rem;
      padding: 0.1rem;
      width: auto;
    }
  }
  .aktiv {
    letter-spacing: 1.5px;
  }
  .social-icon {
    height: 1.2rem;
    width: 1.2rem;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 0.25rem;
    @media(max-width: 600px) {
      height: 2rem;
      width: 2rem;
      i{
        font-size: 1.2rem;
      }
    }
  }
  .footer-nav {
    display: table;
    padding-left: 1.5rem;
    margin-top: 0.5rem;
    li {
      display: table-cell;
      padding: 0 1.2rem;
      font-family: $aktiv;
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: 900;
      letter-spacing: 1px;
      vertical-align: middle;
      &:hover {
        color: $orange;
      }
      &.hide {
        display: none;
      }
    }
  }
  @media(max-width: 1024px) {
    margin-top: 5rem;
    .footer-nav {
      display: block;
      li {
        display: inline-block;
        padding-bottom: 1rem;
      }
    }
  }
  @media(max-width: 768px) {
    .footer-links {
      font-size: 0.6rem;
      padding: 1rem 0;
    }
  }
  @media(max-width: 600px) {
    .footer-links{
      font-size: 0.9rem;
    }
  }
}

/**   LJ GIRLS PAGE LIST STYLES   **/
.lj-girl-mult-img, .lj-girl-summary {
  float: left;
  display: inline-block;
  position: relative;
  z-index: 5;
  text-shadow: 0 0 1rem rgba(100, 100, 100, 0.5);
  &:not(.lj-girl-summary) {
    padding: 7rem 0;
    margin-bottom: -10rem;
    width: 100%;
  }
  .girl-content-container {
    width: 70%;
    margin-left: 27%;
    padding: 5rem 0 10rem 0;
    h2 {
      letter-spacing: 2px;
      font-size: 5.5rem;
      margin: 2.5rem 0;
      span {
        padding: 0 1.2rem;
        padding-right: 0;
        text-shadow: none;
        margin-right: 1rem;
      }
    }
    h4 {
      display: inline-block;
      margin-right: 1rem;
    }
    h6 {
      display: inline-block;
      font-weight: 900;
    }
    .girl-mult-description, .girl-summary-description {
      font-size: 1.1rem;
      line-height: 1.8rem;
      letter-spacing: 1px;
    }
  }
  .girl-mult-button-container, .girl-summary-button-container {
    padding: 2rem 0;
    a.button {
      padding: 1rem 2rem;
      margin-right: 1rem;
    }
  }
  .girl-mult-nav-container {
    padding: 3rem 0;
    .girl-mult-nav {
      overflow: hidden;
      box-shadow: -0.2rem 0.4rem 1rem rgba(0, 0, 0, 0.6);
      height: 16rem;
      width: 55%;
      margin: 4rem 0;
      a {
        position: relative;
        display: block;
        height: 16rem;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        transition-duration: 0.8s;
        span {
          position: absolute;
          bottom: 1rem;
          left: 1rem;
          font-size: 1.3rem;
          font-weight: 700;
          transition-duration: 0.8s;
          text-shadow: 0 0 0.5rem rgba(50, 50, 50, 0.8);
          letter-spacing: 1px;
          i {
            margin-left: 1rem;
            font-size: 1.5rem;
          }
        }
        &:hover {
          transform: scale(1.03);
          span {
            i {
              transform: scale(1.1);
              animation: pulse 1.5s infinite;
            }
          }
        }
      }
    }
  }
  .girl-mult-img-profile, .girl-summary-img-profile {
    position: absolute;
    right: 0;
    width: 27%;
    height: auto;
    z-index: 2;
  }
  @media(max-width: 1024px) {
    &:not(.lj-girl-summary) {
      padding-top: 0;
      padding-bottom: 5rem;
      .girl-content-container {
        padding: 3rem 0 7rem 0;
        margin-left: 18%;
        width: 80%;
        h2 {
          margin-bottom: 1rem;
          font-size: 4.5rem;
        }
      }
    }
    .girl-mult-nav-container {
      margin-top: -2rem;
      .girl-mult-nav {
        width: 85%;
        height: 12rem;
        margin: 2rem 0;
        a {
          height: 12rem;
        }
      }
    }
    .girl-mult-img-profile {
      display: none;
    }
    .girl-content-container {
      width: 90%;
      .girl-mult-description {
        font-size: 0.9rem;
        line-height: 1.2rem;
      }
      .girl-mult-button-container {
        a.button {
          padding: 0.9rem;
        }
      }
    }
  }
  @media(max-width: 768px) {
    &:not(.lj-girl-summary) {
      margin-bottom: 0;
      .girl-content-container {
        padding-top: 2rem;
        padding-bottom: 0;
        margin-left: 5%;
        width: 90%;
        .girl-mult-button-container {
          a.button {
          }
        }
      }
      .girl-mult-nav-container {
        .girl-mult-nav {
          height: 20rem;
          margin: 0 auto;
          a {
            height: 20rem;
          }
        }
      }
    }
  }
  @media(max-width: 400px) {
    .girl-content-container {
      .girl-mult-description {
      font-size: 1.2rem;
      }
    }
  }
}

.mvp {
  position: relative;
}

.mvp-summary .lj-girl-summary {
  overflow: hidden;
}

.lj-girl-summary {
  padding: 5rem 0 10rem 0;
  h2 span {
    margin-right: 2rem;
    text-shadow: none;
  }
  .girl-content-container {
    display: inline-block;
    float: left;
    z-index: 2;
    margin: 0;
    width: 60%;
    padding-left: 15%;
    padding-top: 12rem;
    padding-bottom: 0;
    .girl-summary-description{
      font-size: 1.2rem;
    }
  }
  .girl-summary-img-profile {
    position: relative;
    display: inline-block;
    float: right;
    right: 5%;
    margin-bottom: -20rem;
  }
  .button:hover {
    text-shadow: none;
  }
  @media(max-width: 1024px) {
    .girl-content-container {
      width: 65%;
      padding-left: 5%;
      padding-top: 1rem;
      padding-bottom: 4rem;
      h2 {
        font-size: 4rem;
        margin: 1rem 0;
      }
      .girl-summary-description {
        line-height: 1.3rem;
      }
    }
  }
  @media(max-width: 768px) {
    .girl-content-container {
      width: 80%;
      padding-right: 5%;
      padding-top: 3rem;
      h4 {
        font-size: 1.8rem;
        padding-bottom: 0.4rem;
      }
      .girl-summary-description {
        font-size: 1.1rem;
      }
    }
    .girl-summary-img-profile {
      display: block;
      margin: 0 auto;
      float: none;
      width: 90%;
      right: auto;
      display: none;
    }
  }
  @media(max-width: 500px) {
    padding: 8rem 0 10rem 0;
  }
}

.previous-girls {
  margin-top: -5rem;
  .previous-girls-slider-buttons {
    .button {
      padding: 1.1rem;
      width: 4rem;
      height: 4rem;
      margin: 0 1rem;
    }
  }
  .previous-girls-title {
    padding: 0 10%;
    padding-bottom: 5rem;
    h2 {
      font-size: 5.5rem;
      line-height: 5rem;
      letter-spacing: 3px;
    }
    h5 {
      letter-spacing: 1px;
    }
  }
  @media(max-width: 1024px) {
    padding-top: 4rem;
    .previous-girls-title {
      padding-bottom: 2rem;
    }
  }
  @media(max-width: 768px) {
    padding-top: 0;
    margin-top: -2rem;
  }
}
/** Rookie Of The Year **/
.lj-girl-mult-img {
  &.row {
    width: 100%;
    display: block;
  }

  &.rookie-of-the-year {
    width: 100%;
    display: block;

    .girl-mult-nav-container {
      .girl-mult-nav {
        width: 60%;
        margin-left: 35%;
      }
    }
    .girl-content-container {
      width: 80%;
      margin-left: auto;
      margin-right: 20%;
    }
    @media(max-width: 1024px) {
      .girl-mult-nav-container {
        .girl-mult-nav {
          width: 100%;
          padding-left: 15%;
          margin-left: 0;
        }
      }
      .girl-content-container {
        width: 85%;
        margin-right: 15%;
        margin-left: 5%;
        h2 {
          font-size: 3.5rem;
        }
      }
    }

    @media(max-width:768px) {
      display: flex;
      flex-direction: column-reverse;

      > div:first-child {
        margin-top: 40px;
      }

      .girl-mult-nav-container {
        padding: 0;
        .girl-mult-nav {
          padding: 0 1.5rem;
          height: 20rem;
          margin-bottom: 20px;

          a {
            height: 20rem;
          }
        }
      }
      .girl-content-container {
        padding: 0;
      }
    }
  }
}
.lj-girl-summary {
  .girl-content-container {
    .rookie-title {
      font-size: 4.5rem;
    }
  }
}
/**   WHITE SLIDER STYLES   **/
.white-slider {
  .white-slider-slide {
    padding: 0 2rem;
    .white-slider-image {
      width: 100%;
      height: 15rem;
      position: relative;
      overflow: hidden;
      text-align: center;
      .white-slider-img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: auto;
        height: 100%;
        left: -10%;
      }
    }
    .white-slider-content {
      background-color: $white;
      margin-top: -0.4rem;
      padding: 2rem 1rem;
      position: relative;
      .white-slider-container {
        height: 15rem;
        .white-slider-title {
          letter-spacing: 1px;
          font-family: $nimbus;
          font-weight: 900;
          font-size: 1.4rem;
          text-transform: uppercase;
        }
        .white-slider-description {
          padding: 2rem 0;
          line-height: 1.3rem;
          font-family: $aktiv;
          font-size: 0.7rem;
          font-weight: 300;
          letter-spacing: 1px;
        }
        a {
          position: absolute;
          bottom: 1rem;
          color: $orange;
          font-family: $aktiv;
          font-size: 0.9rem;
          height: 2rem;
          transition-duration: 0.4s;
          i {
            padding-left: 0.2rem;
            font-size: 1rem;
          }
          &:hover {
            i {
              animation: linear pulse infinite 2s;
            }
          }
        }
      }
    }
  }
  @media(max-width: 1024px) {
    .white-slider-slide {
      .white-slider-image {
        height: 12rem;
        .white-slider-img {
        }
      }
      .white-slider-content {
        padding: 1rem;
        .white-slider-container {
          .white-slider-title {
            font-size: 1.2rem;
          }
          .white-slider-description {
            padding: 1rem 0;
            line-height: 0.9rem;
          }
          a {
            bottom: 0;
          }
        }
      }
    }
  }
  @media(max-width: 768px) {
    .white-slider-slide {
      .white-slider-image {
        height: 20rem;
        .white-slider-img {
          width: 100%;
          height: auto;
          left: 0;
        }
      }
      .white-slider-content {
        .white-slider-container {
          .white-slider-description {
            font-size: 1rem;
            line-height: 1.3rem;
          }
        }
      }
    }
  }
}

.tm-popup {
  &--title {
    font-size: calc(15px + .1vw);
    text-transform: uppercase;
  }
  &--content {
    font-size: calc(12px + .1vw);
    line-height: 1.2;
  }
}
.our-team-block {
  h2 {
    font-size: 6.1rem;
    img {
      height: 100%;
      width: auto;
    }
  }
  .col[data-mh="our-team"] {
    min-height: 17rem;
  }
  .team-image-container {
    position: relative;
    i {
      position: absolute;
      top: 30%;
      left: 37.5%;
      font-size: 5rem;
      z-index: 0;

      @media(max-width: 768px) {
         left: 42%;
      }
    }
    img {
      display: inline-block;
      width: 70%;
      height: auto;
      opacity: 1;
      transition-duration: 0.3s;
      z-index: 1;
      position: relative;
    }
    .team-gif, .team-vid {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      margin: auto;
      opacity: 0;
      transition-duration: 0.8s;
      z-index: 2;
      height: 100%;
    }
    .team-vid {
      height: 120%;
      margin-top: -12%;

      @media(max-width: 768px) {
        height: 110%;
        margin-top: -6%;
      }
    }
    &.has-gif:hover {
      .team-img, .team-vid {
        opacity: 0;
      }
      .team-gif, .team-vid {
        opacity: 1;
        display: block;
      }
    }
  }
  h6 {
    padding-top: 0.4rem;
    display: inline-block;
  }
  p {
    margin: 0;
  }
  @media(max-width: 768px) {
    h2 {
      font-size: 4rem;
      img {
        height: 3rem;
      }
    }
  }
}

.logos-block {
  h2 {
    position: relative;
    display: inline-block;
    font-size: 5.5rem;
    margin: 1rem 0;
    img {
      position: absolute;
      height: 6rem;
      width: auto;
      left: -2rem;
      top: 1rem;
    }
  }
  .logos-container {
    padding: 2rem 14%;
    .logo-container {
      padding: 0 1rem;
      display: inline-block;
      vertical-align: top;
      width: 33%;
      .preview-img {
        display: block;
        width: 100%;
        height: auto;
        float: right;
      }
      .logo-links {
        margin-top: -1rem;
        a {
          margin: 1rem 0;
          padding: 0.8rem 4rem;
        }
      }
    }
  }
  @media(max-width: 1024px) {
    .logos-container {
      padding: 2rem 0;
      .logo-container {
        .logo-links {
          a {
            padding: 0.6rem 1.5rem;
          }
        }
      }
    }
  }
  @media(max-width: 768px) {
    .logos-container {
      display: block;
      .logo-container {
        display: inline-block;
        padding: 1rem 0;
        width: 80%;
        .logo-links a {
          padding: 1rem 4rem;
        }
      }
    }
  }
}

.services-slider-big {
  padding: 20rem 0;
  .services-animated-img-up {
    position: absolute;
    top: 0;
    height: auto;
    width: 45%;
    z-index: 6;
  }
  .services-animated-img-down {
    position: absolute;
    bottom: -25rem;
    right: 5rem;
    height: auto;
    width: 30%;
  }
  .slick-slide {
    img {
      width: auto;
      max-width: 80%;
      height: 25rem;
      margin: 1rem auto;
      display: block;
      box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.5);
      @media(max-width: 1024px) {
        height: 14rem;
      }
    }
    .services-slide-content {
      padding-right: 13%;
      .services-description {
        line-height: 1.8rem;
      }
    }
  }
  .slider-pagination-container {
    padding-top: 2rem;
    .button {
      height: 3rem;
      width: 3rem;
      position: relative;
      padding: 0;
      margin-right: 0.5rem;
      i {
        color: $yellow;
        height: auto;
        width: auto;
        position: absolute;
        top: 50%;
        left: 45%;
        transform: translate(-50%, -45%);
      }
      &:hover i {
        color: $orange;
        animation: none;
      }
    }
  }
  @media(max-width: 1024px) {
    padding: 13rem 0;
    .slick-slide {
      img {
        width: 90%;
      }
      .services-slide-content {
        padding-right: 5%;
        h1 {
          font-size: 3rem;
          line-height: 3rem;
        }
        .services-description {
          line-height: 1.2rem;
          font-size: 0.9rem;
        }
      }
    }
    .services-animated-img-down {
      bottom: -10rem;
    }
  }
  @media(max-width: 768px) {
    padding-top: 8rem;
    .slick-slide {
      .services-slide-content {
        padding-left: 5%;
      }
    }
    .slider-pagination-container {
      padding-bottom: 2rem;
    }
  }
}

.services-slider-inline {
  .services-slider-inline-upper {
    position: absolute;
    top: 0;
    left: 0;
    height: 30rem;
    width: auto;
    z-index: 6;
    animation: floating infinite 10s;
  }
  .services-slider-inline-lower {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 30rem;
    width: auto;
    z-index: 6;
    animation: floating infinite 10s;
  }
  .services-slider-inline-header {
    padding-left: 15%;
    h2 {
      line-height: 5rem;
      font-size: 6rem;
      letter-spacing: 2px;
      img {
        height: 100%;
        width: auto;
      }
    }
  }
  .services-slider-container {
    width: 95%;
    margin: 0 2.5%;
    .services-inline-slider {
      padding-bottom: 2rem;
      margin: 0 -2rem;
      .services-slide {
        height: 24rem;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        margin: 0 1.5rem;
        transition-duration: 0.8s;
        cursor: pointer;
        a {
          display: block;
        }
        .services-slider-overlay {
          display: block;
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          background: linear-gradient(transparent, #000);
          transition-duration: 0.8s;
        }
        .services-slide-content {
          position: absolute;
          transition-duration: 0.8s;
          text-shadow: 0 0 1rem rgba(0, 0, 0, 1);
          width: 100%;
          max-height: 4.5rem;
          overflow: hidden;
          bottom: 0;
          > div:first-of-type {
            width: 100%;
            text-align: center;
            font-size: 1.4rem;
          }
          div:not(:first-of-type) {
            font-size: 0.8rem;
            width: 80%;
            margin: 0 10%;
            line-height: 1.2rem;
          }
          span {
            display: inline-block;
            width: 100%;
            i {
              font-size: 1.8rem;
              margin: 0.5rem 0;
              display: block;
            }
          }
          div.services-arrow-container {
            border-bottom: solid 0.3rem $yellow;
            width: 100%;
            margin: 0;
          }
        }
        &:hover {
          .services-slide-content {
            @media(min-width: 1025px) {
              max-height: 25rem;
              span i {
                animation: pulse infinite 2s;
              }
              > *:not(h5) {
                display: block;
              }
            }
          }
        }
      }
    }
    .slider-pagination-container {
      text-align: center;
      button {
        margin: 0 0.5rem;
        height: 3rem;
        width: 3rem;
        padding: 1rem;
      }
    }
  }
  @media(max-width: 1024px) {
    .services-slider-inline-header {
      padding-left: 10%;
    }
    .services-slider-container {
      .services-inline-slider {
        .services-slide {
          height: 18rem;
          .services-slide-content {
            max-height: 4rem;
            div {
              line-height: 1rem;
            }
            .services-slide-description, .services-arrow-container {
              display: none;
            }
          }
          &:hover {
            h5 p {
              margin: 0.5rem;
            }
          }
        }
      }
    }
  }
  @media(max-width: 768px) {
    .services-slider-inline-header {
      padding-left: 5%;
      padding-top: 0;
      h2 {
        font-size: 4.5rem;
        img {
          height: 3.2rem;
        }
      }
    }
    .services-slider-container .services-inline-slider .services-slide {
      background-size: cover;
      background-position: 50% 30%;
      height: 24rem;
    }
  }
  @media(max-width: 500px) {
    .services-slider-container .services-inline-slider .services-slide a div.services-slide-content {
      max-height: 4rem;
      bottom: -0.5rem;
      &:hover {
        bottom: 0 !important;
      }
    }
  }
}
.flip-left-sidebar-full {
  @media(max-width: 999px) {
    display: flex;
    flex-wrap: wrap;
    > aside {
      width: 100%;
      order: 2;
    }
    > section {
      width: 100%;
      order: 1;
    }
  }
}
.charity-block {
  .charity-header {
  }
  h5 {
    font-size: 1.4rem;
    padding: 0.7rem 0;
  }
  p {
    font-size: 0.8rem;
    line-height: 1.4rem;
  }
  a {
    transition-duration: 0.5s;
    font-size: 0.8rem;
    font-weight: 900;
    i {
      font-size: 1.2rem;
      padding-left: 0.5rem;
    }
    &:hover i {
      animation: pulse infinite 2s;
    }
  }
  @media(max-width: 1024px) {
    .padding-xl-h {
      padding-left: 1rem;
      padding-right: 1rem;
      .col-full {
        padding: 0.5rem 0;
        p {
          line-height: 1.2rem;
        }
      }
    }
  }
  @media(max-width: 768px) {
    .charity-main-container {
      height: 30rem;
      overflow: auto;
      margin: 1rem 0;
      margin-right: 0.5rem;
      .charity-main-container-posts{
        visibility: visible!important;
      }
      &::-webkit-scrollbar-track {
        margin-top: 1rem;
        -webkit-box-shadow: inset 0 0 1rem rgba(0, 0, 0, 0.9);
        background-color: transparent;
        border-radius: 0.2rem;
      }
      &::-webkit-scrollbar {
        width: 0.4rem;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $yellow;
        border-radius: 0.2rem;
      }
    }
  }
  @media(max-width: 400px) {
    .charity-main-container{
      h5{
        font-size: 1.7rem;
      }
      p{
        font-size: 1.1rem;
      }
      a{
        font-size: 1.1rem;
      }
    }
  }
}

.charities-page-list, .tips-page-list {
  h3 {
    font-size: 2.8rem;
  }
  .charities-container, .tips-container {
    padding-top: 4rem;
    .charities-thumbnail, .tips-thumbnail {
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
    .charities-content, .tips-content {
      padding-left: 2rem;
      .tips-description {
        line-height: 1.3rem;
      }
      a i {
        margin-left: 0.5rem;
      }
      a:hover i {
        animation: pulse infinite 2s;
      }
    }
    .charities-description {
      line-height: 1.4rem;
      @media(max-width: 400px) {
        font-size: 1.1rem;
      }
    }
  }
  &:not(.charities-page-list) {
    h1 img {
      height: 4.5rem;
      width: auto;
      margin: 0 -1rem;
      @media(max-width: 1024px) {
        height: 3rem;
        margin: 0 -0.7rem;
      }
      @media(max-width: 768px) {
        height: 2.3rem;
      }
    }
  }
  .charities-container + .charities-container::before {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background-color: $black;
    margin-bottom: 4rem;
  }
  @media(max-width: 1024px) {
    padding: 1rem;
    h3 {
      padding-bottom: 1.5rem;
    }
    .charities-container + .charities-container::before {
      margin-bottom: 1rem;
    }
    .ccm-block-page-list-page-entry-horizontal {
      padding-top: 1rem;
    }
  }
  @media(max-width: 768px) {
    h3 {
      font-size: 2rem;
    }
    .charities-container .charities-content {
      padding-left: 0;
    }
  }
  @media(max-width: 768px) {
    .tips-container .tips-content {
      padding-left: 0;
    }
  }
}

.charities-pagination-container, .tips-pagination-container {
  color: $black;
  padding: 2rem 3.3rem;
  font-family: $aktiv;
  font-weight: 900;
  font-size: 0.8rem;
  ul {
    display: inline-block;
    width: auto;
    margin: 0;
    .prev, .next {
      display: none;
    }
    li {
      padding: 0 0.4rem;
      display: inline-block;
      span, a, &.active span {
        font-family: $aktiv;
        color: $black;
        background-color: transparent;
        border: none;
        padding: 0;
        float: none;
      }
      &:hover a {
        color: $orange;
      }
      &.active span {
        color: $orange;
      }
    }
  }
}

.tips-pagination-container {
  color: $white;
  ul {
    padding-left: 0.3rem;
    li {
      span, a {
        color: $white;
      }
    }
  }
}

.product-block {
  padding-bottom: 5rem;
  .product-block-title {
    margin-left: 8%;
  }
  .product-block-product {
    width: 60%;
    margin: 0 20%;
    position: relative;
    .product-block-content {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -25%);
      left: 25%;
      &.col-right {
        right: 0;
        text-align: right;
      }
      &.col {
        width: 50%;
        left: 75%;
        transform: translate(-50% -75%);
      }
      .product-block-description, .product-block-details {
        @media(max-width: 500px) {
          p{
            font-size: 1.1rem;
          }
        }
      }
    }
    .product-block-image {
      .product-block-img {
        width: auto;
        height: auto;
        max-width: 80%;
        max-height: 30rem;
      }
      &.col {
        text-align: right;
      }
    }
    > .col, > .col-right {
      padding: 0 2rem;
    }
  }
  @media(max-width: 1024px) {
    padding-top: 5rem;
    #image-marker {
      height: 3.2rem;
      width: auto;
    }
    .product-block-title {
      margin-left: 3%;
    }
    .product-block-product {
      width: 80%;
      margin: 0 10%;
      .product-block-content {
        padding: 0 1rem;
      }
      .product-block-image .product-block-img {
        padding-top: 5rem;
      }
    }
  }
  @media(max-width: 768px) {
    padding: 0;
    .product-block-product {
      width: 100%;
      margin: 0;
      &:nth-child(odd) {
        display: flex;
        .product-block-image {
          order: 2;
          img {
            float: right;
          }
        }
        .product-block-content {
          order: 1;
        }
      }
      .product-block-content, .product-block-content.col {
        position: relative;
        left: 0%;
        text-align: left !important;
        transform: translate(0,0);
        padding-top: 5rem;
      }
      .product-block-image {
        text-align: left !important;
        padding: 1rem 0;
        .product-block-img {
          padding: 3rem 0;
          max-width: 40vw;
        }
      }
    }
  }
}

.product-month {
  padding-bottom: 14rem;
  padding-top: 7rem;
  .product-month-title {
    h1 {
      font-size: 5rem;
    }
  }
  .product-month-product {
    position: relative;
    margin-top: 5rem;
    .product-month-image {
      padding-left: 3rem;
      .product-month-img {
        max-height: 100%;
        width: auto;
        height: auto;
        max-width: 25rem;
      }
    }
    .product-month-content {
      position: absolute;
      top: 40%;
      transform: translateY(-40%);
      margin: 0 10%;
      width: 35%;
      h4 {
        letter-spacing: 1px;
      }
      .product-month-description, .product-month-details {
        font-size: 0.9rem;
        font-family: $aktiv;
        line-height: 1.5rem;
        letter-spacing: 1px;
      }
      a.button {
        font-family: $aktiv;
        text-transform: capitalize;
        font-size: 0.8rem;
        margin: 2rem 0;
      }
    }
  }
  @media(max-width: 1024px) {
    padding: 3rem 0 10rem 0;
    .product-month-product {
      margin-top: 5rem;
      margin-bottom: 0;
      .product-month-image .product-month-img {
        max-width: 15rem;
      }
      .product-month-content {
        width: 50%;
        margin: 0 6%;
        h4 {
          font-size: 1.3rem;
          margin: 0.2rem 0;
        }
        .product-month-description, .prodouct-month-details {
          font-size: 0.8rem;
          line-height: 1.2rem;
        }
      }
    }
    .product-month-title h1 {
      font-size: 3.5rem;
    }
  }
  @media(max-width: 768px) {
    .product-month-product {
      margin-top: 8rem;
      .product-month-content {
        position: relative;
        width: 100%;
        margin: 0;
        padding: 0 5%;
        margin-top: 12rem;
        margin-bottom: -8rem;
        h4 {
          font-size: 1.8rem;
        }
      }
    }
  }
  @media(max-width:400px) {
    .product-month-product{
      .product-month-description{
        p{
          font-size: 1.1rem;
        }
      }
    }
  }
}

.product-single {
  margin-top: -10rem;
  margin-bottom: -5rem;
  padding-top: 9rem;
  padding-bottom: 30rem;
  .product-single-content {
    padding-left: 5%;
    margin-top: 8rem;
    color: $white;
    text-shadow: 0 0 0.9rem rgba(0, 0, 0, 0.2);
    .center {
      width: 80%;
    }
    .product-name {
      font-size: 5rem;
      line-height: 4.5rem;
      margin-bottom: 3rem;
    }
    .product-description {
    }
    .product-details {
    }
  }
  a.button {
    margin: 5rem 10%;
  }
  .product-image {
    padding-left: 3rem;
    margin-top: 8rem;
    margin-bottom: -3rem;
    .product-img {
      max-height: 100%;
      width: auto;
      height: auto;
      max-width: 25rem;
    }
  }
  @media(max-width: 1024px) {
    padding: 10rem 0 30rem 0;
    margin-top: -5rem;
    .product-image {
      padding-left: 0;
      .product-img {
        max-width: 15rem;
      }
    }
    .product-single-content {
      padding-left: 0;
      h1.product-name {
        font-size: 3rem;
        line-height: 3rem;
        margin-bottom: 0;
      }
      p {
        font-size: 0.9rem;
        margin: 0.2rem 0;
      }
    }
    .product-single-slant {
      left: -146%;
      .product-single-black-and-white {
        right: -25%;
      }
    }
    a.button {
      margin: 0 10%;
    }
  }
  @media(max-width: 768px) {
    padding: 15rem 0 30rem 0;
    .product-single-content {
      h1.product-name {
        font-size: 2.7rem;
        margin-top: 1rem;
      }
      .product-description, .product-details {
        font-size: 0.9rem;
      }
    }
    .product-single-slant {
      display: none;
    }
  }
}

@media(max-width: 768px) {
  .product-image {
    margin-top: 0;
    padding-left: 0;
    padding-right: 1rem;
    text-align: center;
    margin-bottom: 4rem;
    .product-img {
      width: 70%;
      margin-top: -10rem;
      margin-right: 5%;
    }
  }
}

.application {
  margin: 0 3rem;
  padding-bottom: 3rem;
  padding-top: 1rem;
  h1 {
    line-height: 4rem;
    margin-left: -3rem;
    img {
      margin: 0 -1rem;
      height: auto;
      width: 2.5rem;
      @media(max-width: 1024px) {
        width: 1.7rem;
        margin: 0 -0.5rem;
      }
      @media(max-width: 768px) {
        width: 1.2rem;
      }
    }
  }
  .home-contact-title {
    .home-contact-beard-guy {
      padding: 0;
      height: 100%;
      width: auto;
      margin: 0 -1rem;
    }
  }
  form {
    .fields {
      padding: 2rem 0;
      margin: 0 -1rem;
      div h5 {
        padding: 2rem 1rem;
      }
      .field {
        padding: 1.5rem 1rem;
        position: relative;
        &.field-date {
          input {
            width: 100% !important;
            background-color: transparent;
            border: none;
            color: $white;
            padding: 0.5rem 0;
            border-bottom: solid 2px $white;
            font-size: 1rem;
          }
        }
        .form-error {
          height: 1rem;
        }
        label {
          color: $light-grey;
          font-family: $aktiv;
          font-size: 0.9rem;
          font-weight: 700;
          position: absolute;
          transition-duration: 0.5s;
          top: 0;
          left: 1rem;
          display: block !important;
        }
        input[value], textarea, .select2 {
          width: 100%;
          background-color: transparent;
          border: none;
          color: $white;
          padding: 1.5rem 0 0 0;
          border-bottom: solid 2px $white;
          font-size: 1rem;
          &::placeholder {
            color: transparent;
          }
        }
      }
      .field-date.input-has-content {
        label {
          transform: translateY(1rem);
          font-size: 0.8rem !important;
        }
      }
      .select2 {
        float: left;
        .select2-container {
          outline: none;
          width: 100%;
          &:focus {
            outline: none;
          }
          a.select2-choice {
            border: none;
            background-color: transparent;
            border-bottom: solid 2px $white;
            border-radius: 0;
            margin-top: 0.55rem;
            .select2-chosen {
              font-family: $nimbus;
              color: $white;
              font-size: 1.2rem;
            }
            &:focus, &:hover {
              outline: none;
              text-decoration: none;
            }
            .select2-arrow {
              border: none;
              padding-right: 1.5rem;
              width: 4%;
              b {
                display: none;
              }
              &::before {
                content: '';
                border-left: 0.5rem solid transparent;
                border-right: 0.5rem solid transparent;
                border-bottom: 0.5rem solid $white;
                position: absolute;
                top: 0;
              }
              &::after {
                content: '';
                border-left: 0.5rem solid transparent;
                border-right: 0.5rem solid transparent;
                border-top: 0.5rem solid $white;
                position: absolute;
                bottom: 0.4rem;
              }
            }
          }
          &.select2-dropdown-open {
            background-color: $orange;
            .select2-chosen {
              background-color: $orange;
            }
            .select2-arrow {
              background-color: $orange;
              width: 4%;
            }
          }
        }
        a.select2-choice.select2-default span.select2-chosen {
          color: transparent;
        }
      }
      .field-date {
        input.hasDatepicker:not(+ input[val='']) {
          border-bottom-color: $orange;
        }
      }
    }
  }
  .form-actions {
    text-align: center;
  }
  @media(max-width: 768px) {
    margin: 0;
    form {
      margin: 0 3rem;
      padding-bottom: 3rem;
    }
  }
}

.full-page-map {
  .google-map-container {
    background-image: $woodBackground;
    position: relative;
    min-height: 45rem;
  }
  .map-info-container {
    position: absolute;
    overflow: hidden;
    height: 100%;
    .map-legend {
      position: absolute;
      height: calc(90% - 4rem);
      overflow-y: auto;
      margin: 5% 5% 5% 8%;
      letter-spacing: 1px;
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 1rem rgba(0, 0, 0, 0.9);
        background-color: transparent;
        border-radius: 0.2rem;
      }

      &::-webkit-scrollbar {
        width: 0.4rem;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $yellow;
        border-radius: 0.2rem;
      }
      h6 {
        font-size: 0.9rem;
      }
      p {
        margin: 0.2rem 0;
        font-size: 0.8rem;
      }
      .map-focus-location {
        cursor: pointer;
        display: inline-block;
      }
    }
    .map-search-box {
      background-color: $orange;
      background: linear-gradient($yellow 0%, $orange 60%);
      z-index: 10;
      width: 100%;
      padding: 1.5rem;
      position: relative;
      input {
        height: 3rem;
        width: 85%;
        background-image: url(../img/map_pin_grey.png);
        background-position: 4% 30%;
        background-repeat: no-repeat;
        display: inline-block;
        padding-left: 3rem;
        @media(max-width:1024px) {
          padding-left: 0;
        }
        &::-webkit-input-placeholder {
          font-family: $aktiv;
          font-weight: 900;
          font-size: 0.7rem;
          color: #767676;
          letter-spacing: 1px;
        }
        &:focus {
          &::-webkit-input-placeholder {
            color: transparent;
          }
        }
      }
      .button {
        padding: 0;
        display: inline-block;
        text-align: center;
        float: right;
        width: 12.5%;
        height: 3rem;
        position: relative;
        i {
          margin: 0 auto;
        }
        &:after {
          background-position: center;
          position: static;
        }
      }
    }
  }
  .full-page-map-container {
    position: relative;
    .no-results-found {
      background-color: rgba(0, 0, 0, 0.4);
      color: $orange;
      font-size: 1.5rem;
      font-family: $aktiv;
      font-weight: 900;
      position: absolute;
      z-index: 1000;
      height: 100%;
      width: 100%;
      display: none;
      span {
        box-shadow: 0 0 3rem rgba(0, 0, 0, 0.8);
        border: solid 2px $orange;
        text-align: center;
        padding: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        transform: translate(-50%);
        background-image: $woodBackground;
      }
    }
  }
  .headquarters-map-container {
    position: absolute;
    z-index: 9999;
    width: 20%;
    height: 30%;
    top: 0;
    right: 0;
    border: solid $orange 3px;
    box-shadow: -0.3rem 0.3rem 2rem rgba(0, 0, 0, 0.4);
    .headquarters-map {
      z-index: 99999;
      height: 100%;
    }
    table.info-window-for-map {
      padding: 0.2rem;
      th {
        font-size: 1rem;
        padding: 0;
        padding-bottom: 0.2rem;
      }
      td {
        text-align: center;
        padding-left: 0;
      }
      td:not([data-info-window="street_address"]) {
        display: none;
      }
      td[data-info-window="street_address"] {
        text-align: left!important;
      }
    }
  }
  @media(max-width: 1024px) {
    .map-info-container {
      .map-search-box {
        input {
          background-size: 7%;
          text-indent: 1.5rem;
          &::-webkit-input-placeholder {
            font-size: 0.6rem;
          }
        }
      }
    }
  }
  @media(max-width: 768px) {
    .map-info-container {
      position: relative;
      .map-legend {
        position: relative;
        height: 20rem;
        overflow: auto;
      }
      .map-search-box input {
        text-indent: 4rem;
      }
    }
  }
}

.home-page-map {
  .google-map-container {
    .mapboxgl-control-container {
      .mapboxgl-ctrl-top-left {
        @media(max-width: 768px) {
          top: 12%;
        }
      }
    }
  }
}

.google-map-container {
  .marker {
    text-align: center;
    border-radius: 50%;
    background-color: $orange;
    width: 50px;
    height: 50px;
    top: -25px;
    cursor: pointer;
    &::after {
      position: absolute;
      content: '';
      width: 0px;
      height: 0px;
      bottom: -34px;
      left: 0;
      right: 0;
      z-index: -1;
      border: 25px solid transparent;
      border-top: 34px solid $orange;
    }
    span {
      display: block;
      margin-bottom: -6px;
      font-family: $aktiv;
      &:first-child {
        margin-top: 8px;
      }
    }
  }
}
.mapboxgl-popup-content .mapboxgl-popup-close-button {
  font-size: 1.5rem;
}

#stylists-container {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.loading-img-map {
  position: absolute;
  z-index: 10000;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  .uil-ripple-css {
    top: 35%;
    left: 45%;
    display: block;
  }
  @media(max-width: 768px) {
    .uil-ripple-css {
      top: 15%;
      left: 30%;
    }
  }
}

.no-results-found {
  background-color: rgba(0, 0, 0, 0.4);
  color: $orange;
  font-size: 1.5rem;
  font-family: $aktiv;
  font-weight: 900;
  position: absolute;
  z-index: 10;
  height: calc(100% + 15rem);
  width: 100%;
  display: none;
  span {
    box-shadow: 0 0 3rem rgba(0, 0, 0, 0.8);
    border: solid 2px $orange;
    text-align: center;
    padding: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%);
    background-image: $woodBackground;
  }
}

table.info-window-for-map {
  padding-bottom: 0.6rem;
  table-layout: fixed;
  td, th {
    letter-spacing: 1px;
    text-align: left;
    padding: 0.2rem 0.8rem;
    //white-space: nowrap;
  }
  th {
    padding: 0.8rem 0.8rem;
  }
  @media(max-width: 768px) {
    padding: 0.2rem;
    th {
      padding: 0.2rem;
    }
  }

  //span, a {
  //  white-space: nowrap;
  //}
}

.mapboxgl-popup {
  max-width: initial!important;
}

.custom-video-block {
  position: relative;
  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2000;
    background-size: cover;
    background-repeat: no-repeat;
    .custom-video-play {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: auto;
      transform: translateY(-50%);
      height: 5rem;
      width: auto;
      cursor: pointer;
    }
  }
  iframe {
    height: 20rem;
    width: 99.9%;
  }
}

.custom-video-block-small {
  position: relative;
  width: 60%;
  margin: 5rem auto;
  margin-bottom: 2rem;
  .video-container {
    .col-full {
      height: inherit;
    }
  }
  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    .custom-video-play {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: auto;
      transform: translateY(-50%);
      height: 5rem;
      width: auto;
      cursor: pointer;
    }
  }
  iframe {
    height: 20rem;
    width: 99.9%;
  }
  @media(max-width: 1024px) {
    width: 90%;
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .video-container {
      min-height: 46vw;
      .col-full {
        height: inherit;
      }
      //overflow: hidden;
    }
    iframe {
      height: inherit !important;
      min-height: 46vw;
    }
  }
}

.custom-image-slider-block {
  position: relative;
  .custom-image-slider {
    background-color: $black;
    .custom-image-slide {
      height: 40rem;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      margin: 1rem;
      @media(max-width:1024px){
        background-size: contain;
        background-position: center center;
      }
    }
    @media(max-width:1024px){
      background-color: transparent;
    }
  }
  .custom-image-slider-arrows {
    position: absolute;
    bottom: 2.5rem;
    left: 2.5rem;
    .button {
      padding: 1rem 1.5rem;
      margin: 0 0.4rem;
      i {
        margin: 0;
      }
    }
    @media(max-width:1024px){
      width: 100%;
      left: 0;
      text-align: center;
    }
  }
}
.lj-girls-slides {
  .custom-image-slider-block {
    .custom-image-slider {
      .custom-image-slide {
        margin: 0;
      }
    }
  }
}

.error-pages {
  text-align: center;
  position: relative;
  min-height: 75vh;
  margin-top: 20vh;
}

.product-slider-block{
  position: relative;
  h2{
    position: absolute;
    margin-top: 3rem;
    width: 20vw
  }
  .product-slide{
    padding: 3rem 10vw;
    @media(max-width:768px){
      padding: 0 3rem;
      padding-top: 2rem;
    }
    .product-slider-image{
      .product-slider-img{
        max-width: 50%;
        width: auto;
        max-height: 20rem;
        height: auto;
        float: right;
        margin-right: 15%;
      }
      @media(max-width:768px){
        .product-slider-img{
          float: none;
          margin: 0 auto;
          max-height: 15rem;
          max-width: 80%;
        }
      }
    }
    .product-slider-content{
      margin-top: 6%;
      .product-slide-description{
        width: 80%;
      }
      @media(max-width:768px){
        width: 80%;
        margin-left: 10%;
        .product-slide-description{
          width: 100%;
        }
      }
    }
  }
  .slider-pagination-container{
    text-align: center;
    margin-bottom: 5rem;
    button{
      padding: 1rem 1.5rem;
      margin: 0 0.5rem;
      i{
        margin: 0;
      }
    }
  }
}

@media(max-width: 600px) {
  .awesome-happenings-full-list {
    .happenings {
      .happenings-content {
        .happenings-description {
          height: 7rem;
        }
      }
    }
  }
}

.suggestion-title {
  span {
    display: inline-block;
  }
  .suggestion-scissors {
    margin-right: -1rem;
    width: 2.5rem;
    height: auto;

    @media(max-width: 1024px) {
      width: 1.7rem;
      margin-right: -.5rem;
    }
    @media(max-width: 768px) {
      width: 1.4rem;
    }
  }
}

.small-space-paragraph p {
  line-height: 1rem;
}
