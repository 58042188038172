/**   COLORS   **/
$red: #fd0000;
$orange: #eb5d1b;
$black: #010101;
$yellow: #f8ec24;
$white: #ffffff;
$dark-grey: #767676;
$light-grey: #8d8d8d;
$woodBackground: url(../img/bg_wood.jpg);

/**   FONT STYLES   **/
$aktiv:"aktiv-grotesk";
$nimbus:"nimbus-sans-condensed";

//TODO:: BASIC MIXINS
@mixin hover() {
  cursor: pointer;
  transition-duration: 0.3s;
}

@mixin canDisable() {
  &:disabled, &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
  }
}

@mixin bgOrangeYellow {
  background-color: $orange;
  background: linear-gradient($orange 0%, $yellow 60%);
}

@mixin bgYellowOrange {
  background-color: $orange;
  background: linear-gradient($yellow 0%, $orange 60%);
}

@mixin verticalCenter() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin clearFloat() {
  &::before, &::after {
    content: '';
    clear: both;
    display: block;
  }
}
@mixin clearButtonStyle() {
  border: none;
  background-color: transparent;
  box-shadow: none;
}

@mixin bothCenter() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin bgMain() {
  background-repeat: no-repeat;
  background-position: center center;
}

@mixin coverBg() {
  @include bgMain;
  background-size: cover;
}

@mixin containBg() {
  @include bgMain;
  background-size: contain;
}

@mixin fullScreen() {
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
}

@mixin fullContainerSize() {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin fixedOverlay() {
  @include fullScreen;
  position: fixed;
  z-index: 1000;
}

@mixin overlay() {
  @include fullContainerSize;
  position: absolute;
  z-index: 1001;
}

@mixin resetPosition() {
  top: initial;
  right: initial;
  bottom: initial;
  left: initial;
  transform: translate(0, 0);
}

@mixin fontAwesome() {
  font: normal normal normal 14px/1 FontAwesome;
}

@mixin angleLeft() {
  @include fontAwesome;
  content: '\f104';
}

@mixin angleRight() {
  @include fontAwesome;
  content: '\f105';
}

@mixin angleDown() {
  @include fontAwesome;
  content: '\f107';
}

@mixin chevronLeft() {
  @include fontAwesome;
  content: '\f053';
}

@mixin chevronRight() {
  @include fontAwesome;
  content: '\f054';
}

@mixin chevronDown() {
  @include fontAwesome;
  content: '\f078';
}

@mixin sqaure() {
  @include fontAwesome;
  content: '\f096';
}

@mixin squareChecked() {
  @include fontAwesome;
  content: '\f046';
}

@mixin roundChecked() {
  @include fontAwesome;
  content: '\f05d';
}

@mixin check() {
  @include fontAwesome;
  content: '\f00c';
}

@mixin edit() {
  @include fontAwesome;
  content: '\f040';
}

@mixin close() {
  @include fontAwesome;
  content: '\f2d4';
}

@mixin removeListStyles() {
  list-style: none;
  margin: 0;
  li {
    margin: 0;
  }
}

@mixin pseudoElement {
  content: '';
  display: block;
}

@mixin pseudoElements {
  &::before, &::after {
    content: '';
    display: block;
  }
}
