.angled-layout{
  height: 10rem;
  width: 100%;
  background-color: #bbb;
  position: relative;
}
.custom-map-block .google-map-container{
  margin-bottom: 0;
}
.angle-right{
  margin-bottom: 0;
  position: relative;
}
.lj-girl-mult-img{
  margin-bottom: 0;
}

#c5{
  opacity: 1!important;
}
