.aktiv{font-family:$aktiv;}
.nimbus{font-family:$nimbus;}
.white-text,.white-text-hover:hover{color:$white;}
.yellow-text,.yellow-text-hover:hover{color:$yellow;}
.orange-text,.orange-text-hover:hover{color:$orange;}
.black-text,.black-text-hover:hover{color:$black;}
.light-grey-text,.light-grey-text-hover:hover{color:$light-grey;}
.dark-grey-test,.dark-grey-text-hover:hover{color:$dark-grey;}

strong{
  font-weight: 900;
}

/**   HEADER SIZES   **/
h1{font-size:6rem;line-height:5.5rem;}
h2{font-size:4rem;}
h3{font-size:3.6rem;}
h4{font-size:2.7rem;}
h5{font-size:1.4rem;}
h6{font-size:1.2rem;}

/**   FONT SIZES   **/
.font-xs{font-size:0.6rem;}
.font-sm{font-size:0.8rem;}
.font-md{font-size:1rem;}
.font-lg{font-size:1.2rem;}
.font-xl{font-size:1.4rem;}

@media(max-width:1024px){
  h1{font-size:4rem;line-height:3.8rem;}
  h2{font-size:2.5rem;}
  h3{font-size:2rem;}
  h4{font-size:1.7rem;}
  h5{font-size:1.4rem;}
  h6{font-size:1.2rem;}
}

@media(max-width:768px){
  h1{font-size:3rem;}
  h2{font-size:2rem;}
  h3{font-size:1.5rem;}
  h4{font-size:1.3rem;}
  h5{font-size:1.2rem;}
  h6{font-size:1.2rem;}
}

@media(max-width: 400px) {
  #c5 p{font-size: 1.3rem;}
}