a.button, .button {
  padding: 1rem 3.5rem;
  font-size: 0.8rem;
  font-weight: 900;
  letter-spacing: 0.6px;
  transition-duration: 0.6s;
  text-transform: capitalize;
  font-family: $aktiv;
  border: solid 2px transparent;
  border-radius: 0;
  color: $white;
  display: inline-block;
  background-color: transparent;
  i.fa-angle-right {
    color: $yellow;
    margin-left: 0.4rem;
    transition-duration: 0.4s;
  }
  &:hover {
    text-shadow: none!important;
    i.fa-angle-right {
      animation: button-pulse 2s infinite;
      color: $orange;
    }
  }
}

a.button-clear, .button-clear{
  background-color: transparent;
  border-color: transparent;
}

a.button-orange, .button-orange {
  background-color: $orange;
  color: $white;
  border-color: $orange;
  &:hover{
    background-color: $yellow;
    color: $orange;
  }
}

a.button-yellow, .button-yellow{
  background-color: $yellow;
  color: $black;
  border-color: $yellow;
  &:hover{
    background-color: $orange;
  }
}

a.button-white, .button-white{
  background-color: $white;
  color: $orange;
  border-color: $white;
  &:hover{
    background-color: $orange;
    color: $white;
  }
}

a.giant-checkin-button, .giant-checkin-button{
  padding: 0;

  width: 60%;
  font-weight: 900;
  font-family: nimbus-sans-condensed;

  text-transform: uppercase;
  font-size: calc(1vw + 5rem);
  border-width: 6px;
}

a.button-orange-inverted, .button-orange-inverted{
  border-color: $orange;
  color: $white;
  &:hover {
    background-color: $white;
    color: $orange;
  }
}

a.button-white-inverted, .button-white-inverted{
  border-color: $white;
  &:hover {
    background-color: $white;
    color: $orange;
  }
}

a.button-light-grey{
  background-color: $light-grey;
  color: $orange;
  border-color: $light-grey;
  &:hover{
    background-color: $yellow;
    border-color: $orange;
  }
}

a.button-dark-grey{
  background-color: $dark-grey;
  color: $yellow;
  border-color: $dark-grey;
  &:hover{
    background-color: $orange;
    border-color: $yellow;
  }
}

a.button-black{
  background-color: $black;
  color: $yellow;
  border-color: $black;
  &:hover{
    background-color: $orange;
    border-color: $yellow;
  }
}

a.buttonless-button{
  display: inline-block;
  width: 100%;
  text-align: center;

  font-family: nimbus-sans-condensed;
  font-weight: 900;
  font-size: calc(1.5rem + 1vw);

  color: $white;
  text-transform: uppercase;

  margin-bottom: 1rem;
}

.close-icon {
  height: 2rem;
  width: 2rem;
  border: solid 1px transparent;
  margin-top: 20%;
  cursor: pointer;
  .close-bar {
    height: 2px;
    width: 2rem;
    background-color: $white;
    transition-duration: 0.8s;
    margin-top: 50%;
  }
  .close-bar:first-child {
    transform: rotate(45deg);
  }
  .close-bar:nth-child(2) {
    transform: rotate(-45deg);
    margin-top: -0.1rem;
  }
}

.header-locations-link{
  font-family: $nimbus!important;
  text-transform: uppercase!important;
  font-size: 1.3rem!important;
  i{
    display: inline-block;
    vertical-align: top;
    &:nth-child(2){
      margin-top: 0.2rem;
    }
  }
  &:hover{
    color: $yellow;
  }
}


@keyframes button-pulse {
  0% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.6);
    opacity: 1;
  }
  100% {
    transform: scale(1.1);
    opacity: 0.8;
  }
}
