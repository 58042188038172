/** CONCRETE DASHBOARD FIX **/
div.ccm-panel-detail {
  display: block;
}

div#ccm-tooltip-holder {
  margin-top: 49px;
}

@import 'partials/normalize';
@import 'partials/variables';

html {
  min-height: 100%;
  min-width: 100%;
  body {
    min-height: 100%;
    min-width: 100%;
    &.body-menu-open {
      overflow-y: hidden;
    }
    &.is-edit-mode {
      @import 'partials/editmode';
    }
    &.logged-in {
      .header-bar {
        z-index: 99 !important;
      }
      #c5{
        opacity: 1;
      }
    }
    &.modal-open {
      overflow-y: hidden;
    }
    @import 'partials/fonts';
    @import 'partials/buttons';
    @import 'partials/animations';
    @import 'partials/elements';
    @import 'partials/main';
    @import 'partials/responsive';
    #c5 {
      overflow-x: hidden;
      background-image: $woodBackground;
      background-size: contain;
      font-family: $aktiv;
      .center-small {
        width: 75%;
        margin: 0 auto;
        @media(max-width:1024px){
          width: 90%;
        }
      }
      main {
        position: relative;
        width: 100%;
        &.main-margin {
          margin-top: 14rem;
          @media(max-width: 1024px) {
            margin-top: 10rem;
          }
          @media(max-width: 768px){
            margin-top: 8rem;
          }
        }
      }
    }
  }

  body.logged-in #c5 {
    .header-bar {
      position: relative;
      top: 0 !important;
    }
  }

  body.edit-mode #c5 {
    .header-bar {
      position: relative;
      top: 0 !important;
    }
  }

  .select2-drop {
    background-color: #000;
    .select2-results {
      background-color: #000;
      li.select2-result {
        background-color: #000;
        color: #fff;
        &.select2-highlighted {
          background-color: #eb5d1b;
          color: #000;
        }
      }
    }
  }

}

/**   MEDIA QUERIES   **/
@media(max-width: 1300px) {
  div.center {
    width: 80%;
  }
}

@media(max-width: 1024px) {
  html {
    font-size: 2vw;
  }
  div.center {
    width: 90%;
  }
}

@media(max-width: 768px) {
  html {
    font-size: 2.8vw;
  }
}

@media(max-width:500px){
  html {
    font-size: 3vw;
  }
}

/**  TEAM TOOLTIP STYLES   **/
.team-member-tooltip {
  padding: 1.2rem;
  max-width: 30vw;
  font-family: 'aktiv-grotesk';
  color: white;
  font-size: 0.9rem;
  line-height: 1.3rem;
  background: #000;
  letter-spacing: 1px;
  border-color: #000;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.6);
  border-radius: 0;
  z-index: 100;
  @media(max-width: 1024px) {
    max-width: 70vw;
  }
  @media(max-width: 768px) {
    max-width: 100vw;
  }
}

/**    LOGIN PAGE   **/
.login-page-container {
  #login-page {
    position: relative;
    height: 100vh;
    .login {
      top: 50%;
      left: 0;
      right: 0;
      background-image: url(../img/bg_wood.jpg);
      transform: translateY(-50%);
      height: auto;
      width: auto;
      position: absolute;
      border: solid 2px black;
      box-shadow: 0 0 2rem #000;
      color: #eb5d1b;
      font-family: 'aktiv-grotesk';
      button.btn {
        background-color: transparent;
        border: solid #eb5d1b 2px;
        color: #eb5d1b;
        padding: 0.6rem 1.5rem;
        font-size: 1.2rem;
        font-family: 'nimbus-sans-condensed';
        font-weight: 900;
        text-transform: uppercase;
        transition-duration: 0.5s;
        &:hover {
          background-color: #eb5d1b;
          color: #fff;
        }
      }
      input {
        margin-bottom: 1em;
        &:focus {
          border-color: transparent;
        }
      }
    }
  }
}

.preloader-container {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 10000;
  background-image: url(../img/bg_wood.jpg);
  background-size: cover;
  .preloader-img-container {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    .preloader-img {
      height: 10rem;
      width: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      animation: delay 2s linear,  2s bigEntrance linear 2s, 8s loaderFloating linear 3.4s infinite;
    }
  }
}

.bigEntrance {
  animation-name: bigEntrance;
}

@keyframes delay {
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

@keyframes bigEntrance {
  0% {
    transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
    opacity: 0;
  }
  30% {
    transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
    opacity: 1;
  }
  45% {
    transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  60% {
    transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  75% {
    transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  90% {
    transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  100% {
    transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
}


@keyframes loaderFloating {
  0% {
    transform: translateY(0%) rotate(0deg);
  }
  12.5%{
    transform: translateY(2%) rotate(2deg);
  }
  25%{
    transform: translateY(4%) rotate(4deg);
  }
  37.5%{
    transform: translateY(2%) rotate(2deg);
  }
  50% {
    transform: translateY(0%) rotate(0deg);
  }
  62.5%{
    transform: translateY(-2%) rotate(-2deg);
  }
  75%{
    transform: translateY(-4%) rotate(-4deg);
  }
  87.5%{
    transform: translateY(-2%) rotate(-2deg);
  }
  100% {
    transform: translateY(0%) rotate(0deg);
  }
}

.grecaptcha-logo, .grecaptcha-badge {
  display: none !important;
}

body{
  &[data-page="174"]{
    #c5{
      .main-banner{
        top: -5rem;
        @media(max-width:1024px){
          top: -2rem;
        }
      }
    }
  }
  &[data-page="173"], &[data-page="263"]{
    #c5{
      .main-banner{
        top: -12rem;
        @media(max-width: 1024px){
          top: -5rem;
        }
      }
    }
  }
  &[data-page="188"]{
    #c5{
      .main-banner{
        top: -8rem;
        @media(max-width:1024px){
          top: 0;
        }
        @media(max-width:768px){
          img.main-banner-img{
            margin-left: 0;
          }
        }
      }
    }
  }
  &[data-page="239"]{
    #c5{
      .main-banner{
        top: -15rem;
        @media(max-width:1024px){
          top: 0;
        }
      }
    }
  }
  &[data-page="177"]{
    #c5{
      .main-banner{
        top: -5rem;
      }
    }
  }
  &[data-page="242"]{
    #c5{
      .main-banner{
        top: -10rem;
        @media(max-width:1024px){
          top: -3rem;
        }
        @media(max-width:768px){
          img.main-banner-img{
            margin-left: -24%;
          }
        }
      }
    }
  }
  &[data-page="243"]{
    #c5{
      .main-banner{
        top: -3rem;
        @media(max-width:1024px){
          top: 0;
        }
        @media(max-width:768px){
          top: -2rem;
        }
      }
    }
  }
  &[data-page="178"]{
    #c5{
      .main-banner{
        @media(max-width:768px){
          img.main-banner-img{
            margin-left: 0;
          }
        }
      }
    }
  }
}

.recaptcha-holder {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

html body a.buttonless-button {
  display: none;
}
