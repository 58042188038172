
.background-orange, .background-orange-hover:hover {
  background-color: $orange;
}

.background-black, .background-black-hover:hover {
  background-color: $black;
}

.background-yellow, .background-yellow-hover:hover {
  background-color: $yellow;
}

.background-white, .background-white-hover:hover {
  background-color: $white;
}

.background-light-grey, .background-light-grey-hover:hover {
  background-color: $light-grey;
}

.background-dark-grey, .background-dark-grey-hover:hover {
  background-color: $dark-grey;
}

.background-wood {
  background-image: $woodBackground;
}

.background-yellow-orange {
  background-color: $orange;
  background: linear-gradient($yellow 0%, $orange 60%);
}

.background-orange-yellow {
  background-color: $orange;
  background: linear-gradient($orange 0%, $yellow 60%);
}

.angle-right {
  z-index: 5;
  background-size: 100% 100%;
  font-size: 1.2rem;
  &:first-child {
    margin-top: -7rem;
    @media(max-width: 1024px) {
      margin-top: 1rem;
    }
  }
  &.product-single-background {
    background-size: 120% 120%;
  }
  &.product-background {
    margin-top: -12rem;
    &.product-month {
      margin-bottom: -10rem;
    }
  }
  &.career-background {
    padding: 18rem 0;
    margin-bottom: 4rem;
  }
  @media(max-width: 1024px) {
    &.services-slider-background {
      background-size: 150% 100%;
    }
    &.product-background {
      margin-top: -5rem;
      background-size: 150% 120%;
    }
    &.product-single-background {
      background-size: 130% 100%;
      margin-top: -10rem;
    }
    &.contact-background {
      background-size: 150% 110%;
      margin-top: -3rem;
    }
    &.lj-girl-background {
      background-size: 120% 100%;
    }
    &.career-background {
      background-size: 200% 100%;
      margin-top: -13rem;
      background-position: 78% 0%;
      padding-top: 20rem;
      padding-bottom: 15rem;
      aside {
        display: none;
      }
      section {
        width: 100%;
      }
    }
  }
  @media(max-width: 768px) {
    background-size: 150% 100%;
    background-position: center center;
    &.contact-background {
      background-size: 200% 120%;
    }
    &.product-background {
      background-size: 160% 100%;
    }
    &.product-single-background {
      background-size: 200% 100%;
      margin-top: -12rem;
    }
    &.lj-girl-background {
      background-size: 200% 110%;
      background-position: 0 0;
    }
    &.career-background {
      background-size: 300% 100%;
      background-position: 78% 0%;
      padding-top: 25rem;
      padding-bottom: 12rem;
      margin-top: -16rem;
      p {
        margin: 0;
      }
    }
  }
}

.angled-layout {
  position: absolute;
  width: 100%;
  z-index: 5;
  height: 30rem;
  overflow: hidden;
  &.layout-left {
    transform: rotate(-15deg);
    right: -13%;
    margin-top: 10%;
    .black-white-left {
      transform: rotate(15deg);
      position: absolute;
      background-size: cover;
      height: 120%;
      width: 55rem;
      right: 8%;
      top: -25%;
    }
    @media(max-width: 1024px) {
      right: -35%;
      margin-top: 9%;
      .black-white-left {
        height: 90%;
        width: 35rem;
        right: 30%;
      }
    }
  }
  &.layout-right {
    transform: rotate(-15deg);
    left: -11%;
    z-index: 1;
    margin-top: -40%;
    .black-white-right {
      transform: rotate(15deg);
      position: absolute;
      background-size: cover;
      height: 120%;
      width: 55rem;
      left: 5%;
      top: 5%;
    }
    @media(max-width: 1024px) {
      left: -35%;
      margin-top: -66.5%;
      .black-white-right {
        height: 80%;
        width: 35rem;
        left: 25%;
        top: 35%;
      }
    }
  }
  @media(max-width: 768px) {
    display: none;
  }
}

/**   ARROWS   **/
.link-arrow {
  height: 0.5rem;
  width: 0.3rem;
}

/**   CONCRETE FIXES   **/
.login {
  position: absolute;
  z-index: 100;
  background-color: white;
  width: 100%;
  right: 0;
  left: 0;
  margin: auto;
}

/**   CONCRETE THEMES   **/
.hero-parallax-background {
  background-attachment: fixed;
  position: relative;
  display: inline-block;
  @media(max-width: 1024px) {
    background-attachment: initial;
    background-size: auto 80%;
    background-position: center top;
  }
}

.margin-xs-vert {
  margin: 2rem 0;
}

.margin-sm-vert {
  margin: 3rem 0;
}

.margin-md-vert {
  margin: 4rem 0;
}

.margin-lg-vert {
  margin: 5rem 0;
}

.margin-xl-vert {
  margin: 6rem 0;
}

.padding-xs-vert {
  padding: 2rem 0;
}

.padding-sm-vert {
  padding: 3rem 0;
}

.padding-md-vert {
  padding: 4rem 0;
}

.padding-lg-vert {
  padding: 5rem 0;
}

.padding-xl-vert {
  padding: 6rem 0;
}

.looking-good-padding {
  padding-top: 16rem;
  padding-bottom: 10rem;
  background-size: contain;
  margin-top: -15rem;
  #image-marker {
    height: 4rem;
  }
  @media(max-width: 1024px) {
    background-size: cover;
    background-position: bottom center;
    #image-marker {
      max-height: 5rem;
    }
  }
  @media(max-width: 768px) {
    margin-top: -14rem;
    background-size: contain;
    background-position: bottom center;
    padding-top: 12rem;
    padding-bottom: 2rem;
    h2 span span span {
      width: 78%;
      margin: 0 auto;
      display: block;
      font-size: 4rem !important;
      line-height: 3.5rem;
    }
    h2:nth-child(2) {
      margin-top: -2rem;
    }
    a.button {
      margin-top: 2rem;
      padding: 1rem;
    }
    #image-marker {
      max-height: 2.8rem;
    }
  }
}

.background-cover {
  background-size: cover;
}

.background-contain {
  background-size: contain;
}

.accent-letters {
  background-color: $white;
  padding: 0 1rem;
  text-align: center;
}

.line-height-sm {
  line-height: 1.2rem;
}

.line-height-md {
  line-height: 1.4rem;
}

.line-height-lg {
  line-height: 1.6rem;
}

.line-height-xl {
  line-height: 1.8rem;
}

/**   MISC   **/
.header-logo {
  height: 8rem;
  width: auto;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  @media screen and (min-width: 1025px) and (max-width: 1499px) {
    height: 12vw;
    width: 12vw;
    margin-top: calc(0 - (1rem + .75vw));
  }
  @media(max-width: 850px) {
    height: 7rem!important;
  }
}

.centered-image {
  margin: 0 auto;
}

.img-full-responsive {
  width: 100%;
  height: auto;
  display: block;
}

.girls-image-block {
  position: absolute;
  width: 90%;
  height: auto;
  right: 0;
  @media(max-width: 1024px) {
    right: -15%;
  }
  @media(max-width: 768px) {
    display: none;
  }
}

.centered-button {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.rotate45 {
  transform: rotate(45deg);
}

.rotate-45 {
  transform: rotate(-45deg);
}

div.form-error {
  height: 1rem;
  color: $orange;
}

#image-marker {
  max-height: 5.2rem;
  max-width: 5.2rem;
  width: auto;
  @media(max-width: 1024px) {
    max-height: 3.5rem;
  }
  @media(max-width: 768px) {
    max-height: 2.3rem;
  }
}

.guy-thing-mustache {
  margin-right: 2rem;
  @media(max-width: 768px) {
    margin-right: 1rem;
    height: 1.5rem;
    width: auto;
  }
}

.lj-girl-floating-img-container {
  position: relative;
  height: 10rem;
  .lj-girl-floating-img {
    position: absolute;
    width: 40%;
    height: auto;
    right: 0;
    top: -140%;
    z-index: 100;
  }
  @media(max-width: 1024px) {
    display: none;
  }
}

.lj-girl-internal-title {
  letter-spacing: 1px;
  line-height: 4.5rem;
}

.girls-internal-page-title {
  padding: 2rem;
  text-transform: uppercase;
  padding-left: 3rem;
  font-size: 6rem;
  @media(max-width: 1024px) {
    font-size: 3rem;
  }
  @media(max-width: 768px) {
    font-size: 3.5rem;
  }
}

.lj-girl-interview {
  line-height: 1.1rem;
  h4 {
    margin: 2rem 0;
  }
  p {
    margin: 0.5rem 0;
  }
}

.lj-girl-bottom-half {
  @media(max-width: 1024px) {
    background-color: transparent;
    .lj-girls-bottom-content {
      background-color: $white;
    }
  }
}

form {
  padding: 3rem 0 0;
  .form-header {
    padding: 2rem 0;
    h1 {
      line-height: 4rem;
    }
  }
  .fields {
    margin: 0 -1rem;
    .field {
      padding: 0.5rem 1rem;
      position: relative;
      label.control-label {
        color: $white;
        font-family: $aktiv;
        font-size: 1rem;
        position: absolute;
        transition-duration: 0.5s;
        top: -1rem;
        left: 1rem;
        transform: translateY(2rem);
      }
      input[value], textarea, .select2 {
        width: 100%;
        background-color: transparent;
        border: none;
        color: $white;
        padding: 1.5rem 0 0.2rem 0;
        border-bottom: solid 2px $white;
        font-size: 1rem;
        &::placeholder {
          color: transparent;
        }
        &:focus, &:not(:placeholder-shown) {
          box-shadow: none;
          border-bottom-color: $orange;
          + label {
            transform: translateY(0.75rem);
            font-size: 0.8rem;
            color: $orange;
          }
        }
      }
      &.field-radios, &.field-checkboxlist {
        label {
          position: relative;
          top: 0;
          left: 0;
          &.control-label {
            @media all and (-ms-high-contrast:none) {
              top: -2rem;
            }
          }
        }
        .field-options {
          > div {
            display: inline-block;
            padding-top: 0.8rem;
            label {
              display: inline-block;
              span {
                padding: 0 1.5rem;
                display: inline-block;
                position: relative;
                color: $white;
                &::before, &::after {
                  @include pseudoElement;
                  height: 1rem;
                  width: 1rem;
                  position: absolute;
                  left: 0;
                  top: 0;
                  border: solid 2px transparent;
                  transition-duration: 0.3s;
                }
                &::before {
                  border-color: $orange;
                }
              }
              input {
                display: none;
                &:checked {
                  + span {
                    &::after {
                      background-color: $orange;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.field-radios {
        .field-options {
          > div {
            label {
              span {
                &::before, &::after {
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }
      &.field-checkboxlist {
        .field-options {
          > div {
            > div {
              padding: 0.35rem 0;
            }
          }
        }
      }
      input[type=file] {
        margin-top: 2rem;
        color: white;
      }
    }
    .select2 {
      float: left;
      .select2-container {
        outline: none;
        &:focus {
          outline: none;
        }
        a.select2-choice {
          background-color: transparent;
          border-bottom: solid 2px $white;
          border-radius: 0;
          padding: 1.55rem 0 0.2rem 0;
          margin-top: 1.2rem;
          &.select2-default .select2-chosen {
            display: none;
          }
          &:not(.select2-default) + label {
            color: red;
          }
          .select2-chosen {
            font-family: $aktiv;
            color: $white;
            font-size: 1.2rem;
            margin-top: -1.8rem;
          }
          &:focus, &:hover {
            outline: none;
            text-decoration: none;
          }
          .select2-arrow {
            border: none;
            width: 4%;
            right: 0.5rem;
            b {
              display: none;
            }
            &::before {
              content: '';
              border-left: 0.5rem solid transparent;
              border-right: 0.5rem solid transparent;
              border-bottom: 0.5rem solid $white;
              position: absolute;
              top: 0;
            }
            &::after {
              content: '';
              border-left: 0.5rem solid transparent;
              border-right: 0.5rem solid transparent;
              border-top: 0.5rem solid $white;
              position: absolute;
              bottom: 0.4rem;
            }
          }
        }
        &.select2-dropdown-open {
          background-color: $black;
          border: none;
          .select2-chosen {
            background-color: $black;
          }
          .select2-arrow {
            background-color: $black;
            width: 4%;
          }
          .select2-choice {
            background-color: $black;
          }
          * {
            border: none;
          }
        }
      }
      &.input-has-content {
        label {
          transform: translateY(1rem);
          font-size: 0.8rem !important;
        }
      }
    }
  }
  .form-actions {
    text-align: center;
    padding: 2rem 0;
    .button {
      padding: 1rem 5rem;
    }
  }
}
&.vegas_with_lj {
  form {
    .fields {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .field {
        height: auto !important;
        margin: 0 1% 1rem 1%;
        padding: 0;
        border-bottom: 2px solid #ffffff;
        display: flex;
        align-items: flex-end;
        &.col-1\/3 {
          width: 30%;
        }
        &.field-select {
          label {
            display: none;
          }
        }
        label {
          &.control-label {
            top: -.65rem;
            left: 0;
            &.active {
              transform: translateY(.75rem);
              font-size: .8rem;
              color: #eb5d1b;
              display: block;
            }
          }
        }
        input, textarea {
          height: 60px;
          border-bottom: none;
          &::-webkit-input-placeholder {
            color: transparent;
          }
          &:-ms-input-placeholder {
            color: transparent;
          }
          &::placeholder {
            color: transparent;
          }
        }
        textarea {
          overflow: hidden;
          &.has-value {
            overflow-y: scroll;
          }
        }
      }
    }
  }
  input {
    height: 60px;
    border-bottom: none;
  }
  .select2-offscreen {
    position: absolute;
    top: 0;
    left: 0;
  }
  form .fields .select2 .select2-container {
    height: 60px !important;
    a.select2-choice {
      border-bottom: none;
      .select2-arrow::before {
       //border-bottom-color: $white;
      }
      .select2-arrow::after {
       //border-top-color: $white;
      }
    }
  }
  .select2-container {
    &.form-control {
      width: 100%;
      order: 2;
      //border-bottom: 2px solid #ffffff;
    }
    .select2-choice {
      background-color: transparent;
      //border-bottom: solid 2px #ffffff;
      border-bottom: none;
      border-radius: 0;
      padding: 1.55rem 0 .2rem;
      margin-top: 1.2rem;
      &:hover {
        text-decoration: none;
      }
      > .select2-chosen {
        color: #ffffff;
        font-size: 1rem;
        top: -2rem;
        position: relative;
      }
      .select2-arrow {
        border: none;
        padding-right: 1.5rem;
        width: 4%;
        b {
          display: none;
        }
        &::before {
          content: '';
          border-left: 0.5rem solid transparent;
          border-right: 0.5rem solid transparent;
          border-bottom: 0.5rem solid $white;
          position: absolute;
          top: 0;
        }
        &::after {
          content: '';
          border-left: 0.5rem solid transparent;
          border-right: 0.5rem solid transparent;
          border-top: 0.5rem solid $white;
          position: absolute;
          bottom: 0.4rem;
        }
      }
    }
  }
  .select2-chosen {
    padding-left: 0;
  }
  .select2-choice {
    > span {
      &:first-child {
        padding-left: 0;
      }
    }
  }
  @media(max-width: 1300px) {
    form {
      .fields {
        .field {
          &.col-1\/3 {
            width: 48%;
          }
        }
      }
    }
  }
  @media(max-width: 600px) {
    form {
      .fields {
        .field {
          &.col-1\/3 {
            width: 100%;
          }
          textarea {
            height: 60px;
          }
        }
      }
    }
  }
}
.video-container {
  position: relative;
  .custom-video-block-small & {
    overflow: hidden;
  }
  .youtube-player {
    @include fullContainerSize;
  }
  .poster {
    @include coverBg;
    @include overlay;
  }
  .video-play {
    @include bothCenter;
    z-index: 1100;
    padding: 1rem 1.25rem;
    font-size: 0.8rem;
    font-weight: 900;
    letter-spacing: 0.6px;
    transition-duration: 0.6s;
    text-transform: capitalize;
    font-family: $aktiv;
    border: solid 2px $orange;
    border-radius: 50%;
    display: inline-block;
    background-color: $orange;
    color: $white;
    border-color: $orange;
    &:hover{
      font-size: 1.2rem
    }
  }
}

&[data-page="243"] {
  .angle-right.career-background {
    @media(max-width: 1024px) {
      background-size: 300% 103%;
      padding-bottom: 10rem;
      padding-top: 25rem;
      aside {
        display: block;
        width: 20%;
      }
      section {
        width: 80%;
      }
    }
    @media(max-width: 768px) {
      background-size: 600% 100%;
      padding-top: 35rem;
      margin-top: -20rem;
      section, aside {
        width: 100%;
        img.img-full-responsive {
          display: block;
          height: 20rem;
          width: auto;
          margin: 0 auto;
        }
        .button {
          float: right;
        }
      }
    }
  }
}

.img-responsive {
  height: auto;
  max-width: 100%;
}

.full-page-map{
  .zipcode-search {
    padding: 0;
  }
}

#modal {
  @include fixedOverlay;
  z-index: 100000;
  #modal-overlay {
    @include overlay;
    z-index: 0;
    background-color: $black;
    opacity: 0.6;
  }
  #modal-border {
    @include bothCenter;
    @include bgOrangeYellow;
    padding: 0.5rem;

    #modal-close {
      @include pseudoElements;
      position: absolute;
      top: -2.5rem;
      right: -2.5rem;
      height: 42px;
      width: 42px;
      cursor: pointer;
      z-index: 2;
      border-radius: 50%;
      border: solid 2px $orange;
      background-color: rgba(0, 0, 0, 0.5);
      &::before,
      &::after {
        height: 2px;
        width: 38px;
        background-color: $orange;
        transition-duration: 0.8s;
        margin-top: 50%;
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
        margin-top: -0.1rem;
      }
      &:hover {
        &:before {
          transform: rotate(405deg);
        }
        &:after {
          transform: rotate(-405deg);
        }
      }
      @media(max-width: 768px) {
        right: initial;
        left: 50%;
        transform: translateX(-50%);
        top: -5rem;
      }
    }

    #modal-container {
      #modal-content {
        padding: 1rem;
        background-color: $black;
      }
      .video-container {
        max-height: 80vh;
        .youtube-player {
          min-width: 60vw;
          @media(max-width: 1024px) {
            min-width: 70vw;
          }
          @media(max-width: 768px) {
            min-width: 80vw;
          }
        }
      }
    }
  }
  &[data-type="video"], &[data-type="image"] {
    a {
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
      position: absolute;
      bottom: -5rem;
      left: 50%;
      transform: translate(-50%, 0);
      white-space: nowrap;
    }
    .video-play {
      @include hover;
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
      font-size: 1.5rem;
      padding: 1rem 1.4rem;
      &:hover {
        background-color: $white;
        color: $orange;
        border-color: $white;
      }
      @media(max-width: 1024px) and (min-width: 769px) {
        padding: 1.6rem 2.1rem;
      }
      @media(max-width: 600px) {
        padding: 2rem 2.6rem;
      }
    }
  }
  &[data-type="image"] {
    .popup-image-landscape {
      width: 100%;
      height: auto;
    }
    .popup-image-portrait {
      width: auto;
      max-width: 70vw;
      max-height: 75vh;
    }
  }
  &[data-type="stylist"] {
    color: $white;
    padding: 0.5rem 1rem;
    font-family: $nimbus;
    #modal-container {
      max-height: 80vh;
      overflow-y: auto;
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 1rem rgba(0, 0, 0, 0.9);
        @include bgOrangeYellow;
        border-radius: 0.2rem;
        margin-right: 0.5rem;
      }

      &::-webkit-scrollbar {
        width: 0.7rem;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $yellow;
        border-radius: 0.2rem;
      }
    }
    h5, h6, .stylist-row {
      padding-bottom: 0.25rem;
      letter-spacing: 0.05em;
    }
    #stylists-container {
      padding-top: 1rem;
      font-family: $aktiv;
    }
    .stylist-row {
      > div:last-child {
        padding-top: 13px;
      }
    }
    @media(max-width: 800px) {
      #modal-border {
        width: 80vw;
      }
    }
    @media(max-width: 768px) {
      #modal-container {
        max-height: 70vh;
      }
    }
  }

}

