/**   GENERIC FIXES   **/
html{
  font-size:1vw;

  body{margin:0;padding:0;overflow-x:hidden;box-sizing:border-box;
    * {box-sizing:border-box;-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;text-rendering:optimizeLegibility;}
  }
  main{display:block;}
  .center{width:94%;margin:0 auto;}

  a, a:hover, a:visited, a:focus, a:active, a[href^=tel], img{color:inherit;text-decoration:none;outline:none;border:none;background-color:transparent;}
  *:focus{outline:none;box-shadow:none;}
  ul{list-style:none;margin:0;padding:0;}
  button[type='submit'],button[type='reset'],button[type='button'],button{outline:none;cursor:pointer;-webkit-appearance: button;}
  .pointer{cursor:pointer;}
  .relative{position:relative;}
  .absolute{position:absolute;}
  .inline{display:inline;}
  .inline-block{display:inline-block;}
  .block{display:block;}
  .table{display:table;}
  .table-cell{display:table-cell;}
  .not-clickable{pointer-events:none;}

  /**   FORM FIXES   **/
  textarea{resize:none;}
  input[type], textarea{border-radius:0;}

  /**   FONT FIXES   **/
  h1, h2, h3, h4, h5, h6{padding:0;margin:0;-webkit-margin-before:0;-webkit-margin-after:0;font-weight:100;display:block;}
  .font-weight-xs{font-weight:100;}
  .font-weight-sm{font-weight:300;}
  .font-weight-md{font-weight:400;}
  .font-weight-lg{font-weight:700;}
  .font-weight-xl{font-weight:900;}
  .italic{font-style:italic;}
  .underline{text-decoration:underline;}
  .strike-through{text-decoration:line-through;}
  .uppercase{text-transform:uppercase;}
  .lowercase{text-transform:lowercase;}

  /**   FONT SIZES   **/
  .font-xs{font-size:0.6rem;}
  .font-sm{font-size:0.8rem;}
  .font-md{font-size:1rem;}
  .font-lg{font-size:1.2rem;}
  .font-xl{font-size:1.4rem;}

  /**   SHAPESHIFTER FIXES   **/
  .row{&::after{content:'';clear:both;display:block;}}
  section{background-repeat:no-repeat;background-size:cover;background-position:center;}
  .padding-none-t{padding-top:0;}
  .padding-none-b{padding-bottom:0;}
  .padding-none-l{padding-left:0;}
  .padding-none-r{padding-right:0;}


  /**   PHP VAR_DUMP STYLES   **/
  .xdebug-var-dump {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 35px;
    height: 100vh;
    width: 100vw;
    overflow: auto;
    z-index: 100000;
    background-color: #000;
    color: #bbb;
    box-sizing: border-box;
  }

  .xdebug-var-dump * {
    line-height: initial;
  }

  .xdebug-var-dump font[color="#888a85"], .xdebug-var-dump i {
    color: #ddd;
  }

  /**   DISABLED IN EDIT MODE BLOCK   **/
  .edit-mode::after{
    background-color: #ccc;
    border: solid 2px #aaa;
    content: 'View Disabled in Edit Mode';
    display: block;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-size: 1.5rem;
    padding: 1rem 0;
  }
}